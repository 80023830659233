import React from "react";
import PropTypes from "prop-types";
import { useHistory, Link } from "react-router-dom";
import { withSearch, SearchBox } from "@elastic/react-search-ui";

// Import local components
import GreetingsContainer from "./GreetingsContainer";

// Reducer objects
import { CHANGE_SITE_INFO } from "../constants/actionTypes";

// Material UI Components
import AppBar from "@material-ui/core/AppBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import EmailIcon from "@material-ui/icons/Email";
import InfoIcon from "@material-ui/icons/Info";
import SecurityIcon from "@material-ui/icons/Security";
import ListIcon from "@material-ui/icons/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import WarningIcon from "@material-ui/icons/Warning";
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import GavelIcon from '@material-ui/icons/Gavel';

import { fade, makeStyles } from "@material-ui/core/styles";

// Import logo
import logo from "../assets/logo_light.png";

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: "start",
    zIndex: 20,
    padding: 0,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
  },
  toolbar: {
    minHeight: "50px !important",
    [theme.breakpoints.up("sm")]: {
      minHeight: "64px !important",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    fontFamily: "Black Ops One, Roboto",
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.25),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.35),
    },
    marginLeft: "8px" ,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      marginLeft: "auto",
      width: "auto",
      minWidth: "400px",
      fontSize: "1em",
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  // CSS with search button
  // inputInput: {
  //   outline: "none",
  //   color: "#ffffff",
  //   fontWeight: 500,
  //   background: "inherit",
  //   border: "inherit",
  //   "&:focus": {
  //     border: "none",
  //     boxShadow: "none",
  //   },
  //   padding: theme.spacing(1, 1, 1, 7),
  //   transition: theme.transitions.create("width"),
  //   width: "100%",
  //   [theme.breakpoints.up("sm")]: {
  //     width: 400,
  //     "&:focus": {
  //       width: 400,
  //     },
  //   },
  // },
  inputInput: {
    fontSize: "1em",
    outline: "none",
    color: "#ffffff",
    background: "inherit",
    border: "inherit",
    "&:focus": {
      border: "none",
      boxShadow: "none",
    },
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 400,
      "&:focus": {
        width: 550,
      },
    },
  },
  logoimg: {
    height: "40px",
    width: "67.3px",
    marginRight: "6px"
  },
  link: {
    color: "#ffffff",
    textDecoration: "none",
  },
  menuPaper: {
    marginTop: "48px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "60px",
    },
  },
  menuButton: {
    marginLeft: "16px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function Header({ setSearchTerm, dispatchSI, disableSearch }) {
  const classes = useStyles();

  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleItemSelect(page) {
    setAnchorEl(null);
    dispatchSI({type: CHANGE_SITE_INFO, payload: {open: true, page: page}});
  }

  const handleClearPage = () => {
    setSearchTerm("", { shouldClearFilters: true });
  };

  const goToDonate = () => {
    var newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = 'https://donorbox.org/support-armalytics';
  };

  return (
      <div className={classes.root}>
          <AppBar position="static" color="primary">
            <Toolbar className={classes.toolbar}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="simple-menu"
                onClick={handleClick}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                classes={{
                  paper: classes.menuPaper,
                }}
              >
              <MenuItem onClick={() => handleItemSelect("banMessage")}>
                <ListItemIcon>
                  <GavelIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="May 1st OIC" />
              </MenuItem>
                <MenuItem onClick={() => handleItemSelect("about")}>
                  <ListItemIcon>
                    <InfoIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="About" />
                </MenuItem>
                <MenuItem onClick={() => handleItemSelect("contact")}>
                  <ListItemIcon>
                    <EmailIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Contact" />
                </MenuItem>
                <MenuItem onClick={goToDonate}>
                  <ListItemIcon>
                    <LocalHospitalIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Donate" />
                </MenuItem>
                <MenuItem onClick={() => handleItemSelect("disclaimer")}>
                  <ListItemIcon>
                    <WarningIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Disclaimer" />
                </MenuItem>
                <MenuItem onClick={() => handleItemSelect("terms")}>
                  <ListItemIcon>
                    <ListIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Terms & Conditions" />
                </MenuItem>
                <MenuItem onClick={() => handleItemSelect("privacy")}>
                  <ListItemIcon>
                    <SecurityIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Privacy Policy" />
                </MenuItem>
              </Menu>
              <div>
                <Link to="/" onClick={handleClearPage} className={classes.link} color="inherit">
                  <img className={classes.logoimg} src={logo} alt="Logo" />
                </Link>
              </div>
              <div>
                <Link to="/" onClick={handleClearPage} className={classes.link}>
                  <Typography className={classes.title} variant="h6" component="h1" noWrap>
                    Armalytics
                  </Typography>
                </Link>
              </div>
              {!disableSearch ? (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <SearchBox
                    classname={classes.inputRoot}
                    autocompleteMinimumCharacters={3}
                    autocompleteResults={{
                      sectionTitle: "Results",
                      titleField: "model",
                      urlField: "url_field",
                      shouldTrackClickThrough: true,
                      clickThroughTags: ["autoresult"]
                    }}
                    autocompleteSuggestions={{
                      sectionTitle: "Suggestions",
                    }}
                    debounceLength={50}
                    shouldClearFilters={false}
                    inputProps={{id: "searchbar", className: classes.inputInput, placeholder: "Enter a make, model, classification, calibre, etc.."}}
                  />
                </div>
              ) : (
                <>
                  <div className={classes.search}>
                  </div>
                  <Button size="small" variant="text" color="inherit" onClick={() => history.push("/")}>
                    FRT
                  </Button>
                </>
              )}

              <Button size="small" variant="text" color="inherit" className={classes.menuButton} onClick={() => handleItemSelect("subscribe")}>
                Subscribe
              </Button>
            </Toolbar>
          </AppBar>

      </div>
  );
}

export default withSearch(({ setSearchTerm }) => ({
  setSearchTerm
}))(Header);

Header.propTypes = {
    children: PropTypes.element,
    setSearchTerm: PropTypes.func,
    dispatchSI: PropTypes.func,
    disableSearch: PropTypes.bool,
};
