// HomePage.js
import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
// import AuthRequired from "./AuthRequired";

const authHeadings = ["Canadian Law Comments", "Other Markings"]

export default function Note({ heading, value }) {
  var values = value.trim().split("\\n");
  values = values.map(x => x.trim());
  return (
    <div>
      <Typography variant="subtitle2" component="h5" gutterBottom>{heading}</Typography>
      {(authHeadings.includes(heading)) ?

        <ul>
            {values.map((val, i) => (val != "" ? <li key={i}><Typography variant="body2">{val}</Typography></li> : null))}
        </ul>
      :
        <ul>
          {values.map((val, i) => (val != "" ? <li key={i}><Typography variant="body2">{val}</Typography></li> : null))}
        </ul>
      }

    </div>
  );
}

Note.propTypes = {
    heading: PropTypes.string,
    value: PropTypes.string,
};
