import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router"
import siteInfo from "./siteInfoReducer";

const rootReducer = history => combineReducers({
  router: connectRouter(history),
  siteInfo,
});

export default rootReducer;
