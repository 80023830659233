import { CHANGE_SITE_INFO } from "../constants/actionTypes";
import objectAssign from "object-assign";
import initialState from "./initialState";

export default function siteInfoReducer(state = initialState.siteInfo, action) {
  switch (action.type) {
    case CHANGE_SITE_INFO:
      return objectAssign({}, state, action.payload);
    default:
      return state;
  }
}