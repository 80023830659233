// HomePage.js
import React, { useReducer } from "react";
import { Authenticator } from "aws-amplify-react";
import { Helmet } from "react-helmet";

// Local components
import Header from "./Header";
import LayoutHeader from "./LayoutHeader";
import SiteInfo from "./SiteInfo";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";

// Reducer objects
import reducer from "../reducers/siteInfoReducer"
import initialState from "../reducers/initialState"

// SearchUI components
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import config from "../searchui/SearchConfig";
require("@elastic/react-search-ui-views/lib/styles/styles.css");

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Create new Elastic App Search connection instance
const connector = new AppSearchAPIConnector({
  searchKey: "search-2f7pnod8kxxe8vo7733wk7su",
  engineName: "frt",
  endpointBase: "https://frtv2.ent.ca-central-1.aws.elastic-cloud.com"
});

// Remove valid_date filter helper function
function removeValidDate(state) {
  if ("filters" in state) {
    state["filters"] = state["filters"].filter(x => x["field"] != "valid_date");
  }
  return state;
}

// Additional FRTs
const frts = [{'affected_calibres': '5.56MM NATO',
  'frn': 174702,
  'legal_authority': 87,
  'make': '2A  Armament',
  'model': 'Aethon'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 33643,
  'legal_authority': 96,
  'make': '84 Gun',
  'model': 'Classic'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 33645,
  'legal_authority': 96,
  'make': '84 Gun',
  'model': 'Lobo'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 33647,
  'legal_authority': 96,
  'make': '84 Gun',
  'model': 'Pennsylvania'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 166822,
  'legal_authority': 87,
  'make': 'Adler',
  'model': 'Adler B210'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 132450,
  'legal_authority': 87,
  'make': 'Akdal',
  'model': 'MKA 1919'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 142124,
  'legal_authority': 87,
  'make': 'Akdal',
  'model': 'MKA 1919'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 145170,
  'legal_authority': 87,
  'make': 'Akdal',
  'model': 'TAC-12'},
 {'affected_calibres': '338 LAPUA MAG',
  'frn': 166825,
  'legal_authority': 87,
  'make': 'Albert Arms',
  'model': 'ALR'},
 {'affected_calibres': '243 WIN, 260 REM, 308 WIN, 338 FEDERAL, 6.5MM CREEDMOOR, 7MM-08 REM',
  'frn': 149826,
  'legal_authority': 87,
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Hunter'},
 {'affected_calibres': '308 WIN',
  'frn': 146190,
  'legal_authority': 87,
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Hunter Prototype'},
 {'affected_calibres': '22 LR, 5.56MM NATO',
  'frn': 194622,
  'legal_authority': 87,
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Sporter'},
 {'affected_calibres': '5.56MM NATO, 6.5MM GRENDEL',
  'frn': 162446,
  'legal_authority': 87,
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Varmint'},
 {'affected_calibres': '9MM LUGER',
  'frn': 185642,
  'legal_authority': 87,
  'make': 'Alien Armory',
  'model': 'AA9G'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 185682,
  'legal_authority': 87,
  'make': 'Alien Armory',
  'model': 'AAB-15'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 142275,
  'legal_authority': 87,
  'make': 'Alpharms',
  'model': '07SA'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 147046,
  'legal_authority': 87,
  'make': 'Alpharms',
  'model': '15SA'},
 {'affected_calibres': '9MM LUGER',
  'frn': 185702,
  'legal_authority': 87,
  'make': 'American Defense Manufacturing',
  'model': 'UIC-9'},
 {'affected_calibres': '223 REM',
  'frn': 150466,
  'legal_authority': 87,
  'make': 'American Historical Foundation',
  'model': 'Bushmaster XM15E2S Carbine United States Airborne Commemorative'},
 {'affected_calibres': '9MM LUGER',
  'frn': 159566,
  'legal_authority': 87,
  'make': 'Angstadt Arms',
  'model': 'AA-0940'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 188822,
  'legal_authority': 96,
  'make': 'Ashbury Precision Ordnance',
  'model': 'VX'},
 {'affected_calibres': '223 REM, 5.56MM NATO',
  'frn': 130829,
  'legal_authority': 87,
  'make': 'Astra Arms',
  'model': 'STG4'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 170762,
  'legal_authority': 87,
  'make': 'Axor',
  'model': 'MF-1'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 170342,
  'legal_authority': 87,
  'make': 'Axor',
  'model': 'MF-2'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 130509,
  'legal_authority': 96,
  'make': 'Barnard',
  'model': 'P Chey'},
 {'affected_calibres': '4 GA',
  'frn': 117396,
  'legal_authority': 95,
  'make': 'Bate, G',
  'model': 'Side By Side'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO, 6.8MM SPC',
  'frn': 144624,
  'legal_authority': 87,
  'make': 'Battle Rifle Company',
  'model': 'BR4'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 147565,
  'legal_authority': 87,
  'make': 'Battle Rifle Company',
  'model': 'BR4'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 166942,
  'legal_authority': 87,
  'make': 'Berika',
  'model': 'TA1950'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 167043,
  'legal_authority': 87,
  'make': 'Berika',
  'model': 'TS1950'},
 {'affected_calibres': '500 AUTO MAX',
  'frn': 189362,
  'legal_authority': 87,
  'make': 'Big Horn Armory',
  'model': 'AR500'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 174943,
  'legal_authority': 87,
  'make': 'Billet Rifle Systems (BRS)',
  'model': 'BRS-47'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO, 7.62X39 RUSSIAN',
  'frn': 179963,
  'legal_authority': 87,
  'make': 'Black Creek Labs',
  'model': 'BCL15 Pistol'},
 {'affected_calibres': '9MM LUGER',
  'frn': 178024,
  'legal_authority': 87,
  'make': 'Black Leaf Industries',
  'model': 'BL9'},
 {'affected_calibres': '223 REM',
  'frn': 152326,
  'legal_authority': 87,
  'make': 'Black Rain Ordnance',
  'model': 'Fallout 15 NRA Rifle'},
 {'affected_calibres': '223 REM',
  'frn': 152346,
  'legal_authority': 87,
  'make': 'Black Rain Ordnance',
  'model': 'Fallout 15 Pistol'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 145288,
  'legal_authority': 87,
  'make': 'Bora Arms',
  'model': 'BR99'},
 {'affected_calibres': '4 GA',
  'frn': 68525,
  'legal_authority': 95,
  'make': 'Boswell, Charles',
  'model': 'Side By Side'},
 {'affected_calibres': '308 WIN, 7.62MM NATO',
  'frn': 185828,
  'legal_authority': 89,
  'make': 'Bula Defense Systems',
  'model': 'M21 DMR'},
 {'affected_calibres': '308 WIN, 7.62MM NATO',
  'frn': 169783,
  'legal_authority': 89,
  'make': 'Bula Defense Systems',
  'model': 'XM21'},
 {'affected_calibres': '223 REM, 5.56MM NATO, 9MM NATO',
  'frn': 122799,
  'legal_authority': 87,
  'make': 'Bushmaster',
  'model': 'Carbon 15 Pistol'},
 {'affected_calibres': '223 REM',
  'frn': 126747,
  'legal_authority': 87,
  'make': 'Bushmaster',
  'model': 'Carbon 15 Top Loading'},
 {'affected_calibres': '308 WIN',
  'frn': 132638,
  'legal_authority': 87,
  'make': 'Bushmaster',
  'model': 'LR-308'},
 {'affected_calibres': '223 REM, 5.56MM NATO',
  'frn': 147524,
  'legal_authority': 87,
  'make': 'Bushmaster',
  'model': 'XM15E2S Pistol'},
 {'affected_calibres': '9MM LUGER',
  'frn': 189925,
  'legal_authority': 87,
  'make': 'CK Arms',
  'model': 'CKAR-9'},
 {'affected_calibres': '300 AAC BLACKOUT',
  'frn': 152786,
  'legal_authority': 87,
  'make': 'CMMG',
  'model': 'MK-4 Pistol'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 152106,
  'legal_authority': 87,
  'make': 'CMMG',
  'model': 'MK-47'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 165247,
  'legal_authority': 87,
  'make': 'CMMG',
  'model': 'MK-47 Pistol'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 160166,
  'legal_authority': 87,
  'make': 'CMMG',
  'model': 'MK3 Pistol'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 161767,
  'legal_authority': 87,
  'make': 'CQ',
  'model': 'Type CQ-A Semi-Automatic Rifle'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 123420,
  'legal_authority': 96,
  'make': 'CZ',
  'model': 'CZ550 Safari Classic Magnum'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 163346,
  'legal_authority': 96,
  'make': 'Cadex',
  'model': 'CDX-40 Shadow'},
 {'affected_calibres': '12 GA X 3", 20 GA X 3"',
  'frn': 187342,
  'legal_authority': 87,
  'make': 'Canuck',
  'model': 'Havoc'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 185923,
  'legal_authority': 87,
  'make': 'Caracal',
  'model': 'CAR 814 A2'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 185882,
  'legal_authority': 87,
  'make': 'Caracal',
  'model': 'CAR 816 A2'},
 {'affected_calibres': '223 REM',
  'frn': 123162,
  'legal_authority': 87,
  'make': 'Cavalry Arms',
  'model': 'CAV-15 Scout'},
 {'affected_calibres': '223 REM',
  'frn': 123161,
  'legal_authority': 87,
  'make': 'Cavalry Arms',
  'model': 'CAV-15 Trooper'},
 {'affected_calibres': '223 REM',
  'frn': 128287,
  'legal_authority': 87,
  'make': 'Cavalry Arms',
  'model': 'CAV-15A'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 121754,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'CheyTac'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 121746,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M100 Intervention'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 128468,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M200'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 121748,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M200 Intervention'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 150046,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M300'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 150026,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M300 Intervention'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 121752,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M300 Shiloh'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 125377,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M310'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 125378,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M310'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 123803,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M400'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 121753,
  'legal_authority': 96,
  'make': 'CheyTac',
  'model': 'M400 Shiloh'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 130417,
  'legal_authority': 87,
  'make': 'China Xinshidai',
  'model': 'Type CQ-A-1 Semi-Automatic Rifle'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 186042,
  'legal_authority': 87,
  'make': 'Christensen Arms',
  'model': 'Carbon CA-15 G2'},
 {'affected_calibres': '223 REM',
  'frn': 175091,
  'legal_authority': 87,
  'make': 'Civilian Force Arms',
  'model': 'CFA-15'},
 {'affected_calibres': '502 THUNDER SABRE',
  'frn': 126478,
  'legal_authority': 87,
  'make': 'Cloud Mountain',
  'model': '502 Big Bore Canyon'},
 {'affected_calibres': '502 THUNDER SABRE',
  'frn': 126479,
  'legal_authority': 87,
  'make': 'Cloud Mountain',
  'model': '502 Thunder Boss'},
 {'affected_calibres': '502 THUNDER SABRE',
  'frn': 126476,
  'legal_authority': 87,
  'make': 'Cloud Mountain',
  'model': '502 Thunder Sabre'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 147273,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'C8 CSC SCC'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 184942,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'C8 IUR RCMP'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125660,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'C8A1'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125661,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'C8A2'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125657,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'C8CT'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125655,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'CQB'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125672,
  'legal_authority': 87,
  'make': 'Colt',
  'model': 'SFW'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 195379,
  'legal_authority': 96,
  'make': 'Concari',
  'model': '04'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 195372,
  'legal_authority': 96,
  'make': 'Concari',
  'model': 'Royal'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 195357,
  'legal_authority': 96,
  'make': 'Concari',
  'model': 'Steinbock'},
 {'affected_calibres': '308 WIN',
  'frn': 147623,
  'legal_authority': 87,
  'make': 'Core',
  'model': 'Core-30'},
 {'affected_calibres': '5.56MM NATO, 6.8MM SPC',
  'frn': 160248,
  'legal_authority': 87,
  'make': 'Coronado Arms',
  'model': 'CA-15'},
 {'affected_calibres': '223 REM, 300 AAC BLACKOUT',
  'frn': 181623,
  'legal_authority': 87,
  'make': 'DRD',
  'model': 'Aptus'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 179645,
  'legal_authority': 87,
  'make': 'DTI',
  'model': 'DT10'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 193146,
  'legal_authority': 87,
  'make': 'Dasan',
  'model': 'DAR10'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 52260,
  'legal_authority': 96,
  'make': 'Demas',
  'model': 'Azur'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 141379,
  'legal_authority': 87,
  'make': 'Derya Arms',
  'model': 'MK12'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 194802,
  'legal_authority': 87,
  'make': 'Derya Arms',
  'model': 'VR60'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164268,
  'legal_authority': 87,
  'make': 'Derya Arms',
  'model': 'VR90'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 156869,
  'legal_authority': 87,
  'make': 'Devil Dog Arms',
  'model': 'DDA-15BS'},
 {'affected_calibres': '223 REM',
  'frn': 175204,
  'legal_authority': 87,
  'make': 'Dez Arms',
  'model': 'BR4-15'},
 {'affected_calibres': '223 REM',
  'frn': 175222,
  'legal_authority': 87,
  'make': 'Dez Arms',
  'model': 'TA-15'},
 {'affected_calibres': '308 WIN',
  'frn': 160426,
  'legal_authority': 87,
  'make': 'Diamondback Firearms',
  'model': 'DB-308'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 122359,
  'legal_authority': 87,
  'make': 'Diemaco',
  'model': 'C7CT'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125607,
  'legal_authority': 87,
  'make': 'Diemaco',
  'model': 'C8A2'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 122360,
  'legal_authority': 87,
  'make': 'Diemaco',
  'model': 'C8CT'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 126927,
  'legal_authority': 87,
  'make': 'Diemaco',
  'model': 'C8CT Copy'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125015,
  'legal_authority': 87,
  'make': 'Diemaco',
  'model': 'CQB'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 48505,
  'legal_authority': 87,
  'make': 'Diemaco',
  'model': 'R7'},
 {'affected_calibres': '9MM LUGER',
  'frn': 119327,
  'legal_authority': 87,
  'make': 'Dlask Arms',
  'model': 'DAP601'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 152988,
  'legal_authority': 87,
  'make': 'Double Star',
  'model': 'Star-15 3GR'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 152986,
  'legal_authority': 87,
  'make': 'Double Star',
  'model': 'Star-15 C3'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 152989,
  'legal_authority': 87,
  'make': 'Double Star',
  'model': 'Star-15 Midnight Dragon'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 152990,
  'legal_authority': 87,
  'make': 'Double Star',
  'model': 'Star-15 Patrol Rifle'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 4586,
  'legal_authority': 96,
  'make': 'Dumoulin, Ernest',
  'model': 'Continental 1'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 4633,
  'legal_authority': 96,
  'make': 'Dumoulin, Henri & Fils',
  'model': 'Imperial Magnum'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 4632,
  'legal_authority': 96,
  'make': 'Dumoulin, Henri & Fils',
  'model': 'Imperial Magnum Grand Luxe'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 4628,
  'legal_authority': 96,
  'make': 'Dumoulin, Henri & Fils',
  'model': 'Imperial Magnum Sovereign'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 131462,
  'legal_authority': 96,
  'make': 'EAA',
  'model': 'SAB92SF'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 128807,
  'legal_authority': 96,
  'make': 'EDM Arms',
  'model': '408 XM Series'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 187983,
  'legal_authority': 87,
  'make': 'Eternal',
  'model': 'FX12'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 170844,
  'legal_authority': 87,
  'make': 'F-1 Firearms',
  'model': 'BDR-15 CA Pistol'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 170843,
  'legal_authority': 87,
  'make': 'F-1 Firearms',
  'model': 'BDR-15-3G CA Pistol'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 60256,
  'legal_authority': 96,
  'make': 'FAMARS',
  'model': 'Africa Express'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 60238,
  'legal_authority': 96,
  'make': 'FAMARS',
  'model': 'Venus Express'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 195889,
  'legal_authority': 87,
  'make': 'FN',
  'model': 'FN15 Pistol'},
 {'affected_calibres': '300 WIN MAG',
  'frn': 183962,
  'legal_authority': 87,
  'make': 'Falkor Defense',
  'model': 'FD-30A'},
 {'affected_calibres': '223 REM',
  'frn': 175224,
  'legal_authority': 87,
  'make': 'Fort Discovery',
  'model': 'Expedition'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 144782,
  'legal_authority': 87,
  'make': 'Franklin Armory',
  'model': 'SE-SSP'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 163207,
  'legal_authority': 89,
  'make': 'Fulton Armory',
  'model': 'M21'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 176608,
  'legal_authority': 96,
  'make': 'GAC Rifles',
  'model': 'Big One'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 176607,
  'legal_authority': 96,
  'make': 'GAC Rifles',
  'model': 'Thunder'},
 {'affected_calibres': '9MM LUGER',
  'frn': 190322,
  'legal_authority': 87,
  'make': 'Glarner Waffen',
  'model': 'SPC-A2'},
 {'affected_calibres': '223 REM',
  'frn': 125570,
  'legal_authority': 87,
  'make': 'Global Tactical',
  'model': 'GTAR15'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 160875,
  'legal_authority': 96,
  'make': 'Grande Armeria Camuna (GAC)',
  'model': 'Big One'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 160874,
  'legal_authority': 96,
  'make': 'Grande Armeria Camuna (GAC)',
  'model': 'Thunder'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 58397,
  'legal_authority': 96,
  'make': 'Grant, Stephen & Sons',
  'model': 'Double Barrel Rifle'},
 {'affected_calibres': '4 GA',
  'frn': 56936,
  'legal_authority': 95,
  'make': 'Greener, W W',
  'model': 'Duck Gun'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 175324,
  'legal_authority': 87,
  'make': 'Grey Ghost Precision',
  'model': 'Cornerstone'},
 {'affected_calibres': '308 WIN',
  'frn': 157447,
  'legal_authority': 87,
  'make': 'Grey Ghost Precision',
  'model': 'Specter Heavy'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 29852,
  'legal_authority': 96,
  'make': 'Harris Gunworks',
  'model': 'Talon Safari'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 139842,
  'legal_authority': 87,
  'make': 'Heckler & Koch',
  'model': 'G28'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 123661,
  'legal_authority': 96,
  'make': 'Heym',
  'model': '88B/SS Jumbo'},
 {'affected_calibres': '460 WBY MAG, 600 NITRO EXPRESS',
  'frn': 4929,
  'legal_authority': 96,
  'make': 'Heym',
  'model': 'Express'},
 {'affected_calibres': '308 WIN, 7.62MM NATO',
  'frn': 131409,
  'legal_authority': 87,
  'make': 'Homemade',
  'model': 'AR-10 Copy'},
 {'affected_calibres': '223 REM, 308 WIN',
  'frn': 120316,
  'legal_authority': 87,
  'make': 'Homemade',
  'model': 'AR15'},
 {'affected_calibres': '223 REM',
  'frn': 148323,
  'legal_authority': 87,
  'make': 'Homemade',
  'model': 'BA15'},
 {'affected_calibres': '3" MORTAR',
  'frn': 130350,
  'legal_authority': 95,
  'make': 'Homemade',
  'model': 'Black Powder Mortar'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 158186,
  'legal_authority': 87,
  'make': 'Homemade',
  'model': 'DF15'},
 {'affected_calibres': '223 REM',
  'frn': 162566,
  'legal_authority': 87,
  'make': 'Homemade',
  'model': 'Lebel R1'},
 {'affected_calibres': '50 BMG',
  'frn': 117197,
  'legal_authority': 96,
  'make': 'Homemade',
  'model': 'Single Shot Rifle'},
 {'affected_calibres': '4 GA',
  'frn': 109095,
  'legal_authority': 95,
  'make': 'Horsley, Thomas',
  'model': 'Side By Side'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 157548,
  'legal_authority': 87,
  'make': 'Husan & Eksen',
  'model': 'MKA 1919 Match'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 127846,
  'legal_authority': 87,
  'make': 'Imperial Defence Services',
  'model': 'MG4A5'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 127847,
  'legal_authority': 87,
  'make': 'Imperial Defence Services',
  'model': 'MG4A6'},
 {'affected_calibres': '40 S&W, 9MM LUGER',
  'frn': 195518,
  'legal_authority': 87,
  'make': 'KE Arms',
  'model': 'KE-9'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 4638,
  'legal_authority': 96,
  'make': 'Kimber',
  'model': '89 African'},
 {'affected_calibres': '6X35 TSWG',
  'frn': 129152,
  'legal_authority': 87,
  'make': 'Knights Armament Company',
  'model': 'PDW'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 153787,
  'legal_authority': 87,
  'make': 'Kral',
  'model': 'KRX'},
 {'affected_calibres': '5.56MM NATO, 6.8MM SPC',
  'frn': 130122,
  'legal_authority': 87,
  'make': 'LWRC',
  'model': 'M6'},
 {'affected_calibres': '6.8MM SPC',
  'frn': 153909,
  'legal_authority': 87,
  'make': 'LWRC',
  'model': 'M6.8 PSD Pistol'},
 {'affected_calibres': '5.56MM NATO, 6.8MM SPC',
  'frn': 131494,
  'legal_authority': 87,
  'make': 'LWRC',
  'model': 'M6/M6A2 Pistol'},
 {'affected_calibres': '5.56MM NATO, 6.8MM SPC',
  'frn': 130106,
  'legal_authority': 87,
  'make': 'LWRC',
  'model': 'M6/M6A3'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 153906,
  'legal_authority': 87,
  'make': 'LWRC',
  'model': 'M6IC PSD Pistol'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 196221,
  'legal_authority': 87,
  'make': 'Landor Arms',
  'model': 'LND-106'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 196219,
  'legal_authority': 87,
  'make': 'Landor Arms',
  'model': 'LND-109'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 129450,
  'legal_authority': 96,
  'make': 'Lawton Machine',
  'model': '8000'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 128897,
  'legal_authority': 96,
  'make': 'Lawton Machine',
  'model': '8500'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 126415,
  'legal_authority': 87,
  'make': 'Leitner-Wise Rifle',
  'model': 'LW15-5.56CP'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 126420,
  'legal_authority': 87,
  'make': 'Leitner-Wise Rifle',
  'model': 'LW15-5.56SFODA'},
 {'affected_calibres': '223 REM, 264 LBC-AR, 5.56MM NATO, 6X45',
  'frn': 130627,
  'legal_authority': 87,
  'make': 'Les Baer Custom',
  'model': 'Police Special'},
 {'affected_calibres': '50 BMG',
  'frn': 124643,
  'legal_authority': 96,
  'make': 'Ligamec',
  'model': 'USA50'},
 {'affected_calibres': '50 BMG',
  'frn': 124641,
  'legal_authority': 96,
  'make': 'Ligamec',
  'model': 'Ultralite50'},
 {'affected_calibres': '308 WIN',
  'frn': 195943,
  'legal_authority': 87,
  'make': 'Live Free Armory',
  'model': 'LF-10'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 182882,
  'legal_authority': 96,
  'make': 'Lobaev Arms',
  'model': 'DXL-4'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 182802,
  'legal_authority': 96,
  'make': 'Lobaev Arms',
  'model': 'SVLK-14S'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 120647,
  'legal_authority': 96,
  'make': 'MAG',
  'model': 'GS97'},
 {'affected_calibres': '9MM LUGER',
  'frn': 188284,
  'legal_authority': 87,
  'make': 'MBX',
  'model': 'Pro Series PCC'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 158626,
  'legal_authority': 87,
  'make': 'MKA Arms',
  'model': 'MKA 1919'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 187582,
  'legal_authority': 87,
  'make': 'MKA Arms',
  'model': 'MKA 1919'},
 {'affected_calibres': '12 GA X 3", 20 GA X 3"',
  'frn': 187442,
  'legal_authority': 87,
  'make': 'MKA Arms',
  'model': 'MKA 1919PA'},
 {'affected_calibres': '204 RUGER, 223 REM, 224 VALKYRIE, 300 AAC BLACKOUT, 458 SOCOM, 6.5MM GRENDEL, 7.62X39 RUSSIAN',
  'frn': 181822,
  'legal_authority': 87,
  'make': 'Maccabee Defense',
  'model': 'SLR-MULTI'},
 {'affected_calibres': '37MM RIMMED',
  'frn': 160006,
  'legal_authority': 95,
  'make': "Manufacture Francaise d'Armes & Cycles de St Etienne",
  'model': 'Cannon Breech Punt Gun'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 29567,
  'legal_authority': 96,
  'make': 'Mauser',
  'model': '1908 Brazilian Rifle'},
 {'affected_calibres': '375 CT WILDCAT',
  'frn': 68107,
  'legal_authority': 96,
  'make': 'Mauser',
  'model': 'Sporter'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 29836,
  'legal_authority': 96,
  'make': 'McMillan',
  'model': 'Talon Safari'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 124649,
  'legal_authority': 96,
  'make': 'Medwell & Perrett',
  'model': 'Double Barrel Rifle'},
 {'affected_calibres': '223 REM',
  'frn': 138859,
  'legal_authority': 87,
  'make': 'Mega Arms',
  'model': 'MEGA GTR-3S'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 133643,
  'legal_authority': 87,
  'make': 'Mega Machine Shop',
  'model': 'MEGA Pistol'},
 {'affected_calibres': '416 BARRETT',
  'frn': 142063,
  'legal_authority': 96,
  'make': 'Micor Defense',
  'model': 'Leader 416'},
 {'affected_calibres': '50 BMG',
  'frn': 142062,
  'legal_authority': 96,
  'make': 'Micor Defense',
  'model': 'Leader 50'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 15837,
  'legal_authority': 96,
  'make': 'Modulo Masterpiece',
  'model': 'Wizard Snap Long Range Match'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 135550,
  'legal_authority': 96,
  'make': 'Montana Rifle',
  'model': '1999 DGR'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 153968,
  'legal_authority': 96,
  'make': 'Montana Rifle',
  'model': '1999 SCR-SS'},
 {'affected_calibres': '22 LR',
  'frn': 133396,
  'legal_authority': 87,
  'make': 'Mossberg',
  'model': '702 Plinkster Tactical 22'},
 {'affected_calibres': '22 LR',
  'frn': 146625,
  'legal_authority': 87,
  'make': 'Mossberg',
  'model': '715P'},
 {'affected_calibres': '22 LR',
  'frn': 147706,
  'legal_authority': 87,
  'make': 'Mossberg',
  'model': '715P Duck Commander'},
 {'affected_calibres': '22 LR',
  'frn': 136698,
  'legal_authority': 87,
  'make': 'Mossberg',
  'model': '715T Tactical 22'},
 {'affected_calibres': '22 LR',
  'frn': 147718,
  'legal_authority': 87,
  'make': 'Mossberg',
  'model': '715T Tactical 22 Duck Commander'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 118557,
  'legal_authority': 96,
  'make': 'Nesika',
  'model': 'M'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 118558,
  'legal_authority': 96,
  'make': 'Nesika',
  'model': 'M'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 161267,
  'legal_authority': 96,
  'make': 'Noreen Firearms',
  'model': 'BN408'},
 {'affected_calibres': '223 REM',
  'frn': 166442,
  'legal_authority': 89,
  'make': 'Norinco',
  'model': '305B'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 166443,
  'legal_authority': 87,
  'make': 'Norinco',
  'model': 'NR-401 Semi-Auto Rifle'},
 {'affected_calibres': '40 S&W',
  'frn': 128692,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'ACB'},
 {'affected_calibres': '223 REM, 40 S&W, 45 AUTO, 9MM LUGER',
  'frn': 88076,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'K23B'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 127591,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'K30R'},
 {'affected_calibres': '6.8MM SPC',
  'frn': 127592,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'K68'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 127602,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'K7 Eliminator'},
 {'affected_calibres': '5.45X39 RUSSIAN',
  'frn': 133756,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'K74'},
 {'affected_calibres': '40 S&W, 9MM LUGER',
  'frn': 117772,
  'legal_authority': 87,
  'make': 'Olympic Arms',
  'model': 'KPM LE'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 196186,
  'legal_authority': 87,
  'make': 'Palmetto State Armory',
  'model': 'KS-47G2'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 25626,
  'legal_authority': 87,
  'make': 'Palmetto State Armory',
  'model': 'KS47'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO, 7.62X39 RUSSIAN, 9MM LUGER',
  'frn': 25822,
  'legal_authority': 87,
  'make': 'Palmetto State Armory',
  'model': 'PA-15 Pistol'},
 {'affected_calibres': '9MM LUGER',
  'frn': 164662,
  'legal_authority': 87,
  'make': 'Palmetto State Armory',
  'model': 'PA-X9'},
 {'affected_calibres': '9MM LUGER',
  'frn': 165322,
  'legal_authority': 87,
  'make': 'Palmetto State Armory',
  'model': 'PA-X9 Pistol'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 163926,
  'legal_authority': 87,
  'make': 'Pardus',
  'model': 'SD'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 120313,
  'legal_authority': 96,
  'make': 'Pfeifer Waffen',
  'model': 'Zeliska'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 147181,
  'legal_authority': 87,
  'make': 'Phase 5 Tactical',
  'model': 'Atlas One Pistol'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 147182,
  'legal_authority': 87,
  'make': 'Phase 5 Tactical',
  'model': 'P5T15 CQC Pistol'},
 {'affected_calibres': '20X102',
  'frn': 134637,
  'legal_authority': 95,
  'make': 'Physics Applications',
  'model': 'Universal Receiver'},
 {'affected_calibres': '375 CT WILDCAT, 408 CHEYENNE TACTICAL, 416 CT WILDCAT',
  'frn': 57438,
  'legal_authority': 96,
  'make': 'Prairie Gun Works',
  'model': 'LRT2REP'},
 {'affected_calibres': '375 CT WILDCAT, 408 CHEYENNE TACTICAL, 416 CT WILDCAT',
  'frn': 57439,
  'legal_authority': 96,
  'make': 'Prairie Gun Works',
  'model': 'LRT2SS'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 57697,
  'legal_authority': 96,
  'make': 'Prairie Gun Works',
  'model': 'M18 Ti'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 118380,
  'legal_authority': 96,
  'make': 'Prairie Gun Works',
  'model': 'Timberwolf Tactical'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 126813,
  'legal_authority': 96,
  'make': 'Prechtl, Gottfried',
  'model': 'Mauser 98 Safari'},
 {'affected_calibres': '223 REM',
  'frn': 137657,
  'legal_authority': 87,
  'make': 'Proarms Armory',
  'model': 'Par MARK 3'},
 {'affected_calibres': '223 REM, 5.56MM NATO',
  'frn': 49088,
  'legal_authority': 87,
  'make': 'Professional Ordnance',
  'model': 'Carbon 15'},
 {'affected_calibres': '223 REM',
  'frn': 49092,
  'legal_authority': 87,
  'make': 'Professional Ordnance',
  'model': 'Carbon 15 Pistol'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 28167,
  'legal_authority': 96,
  'make': 'Purdey',
  'model': 'Double Barrel Rifle'},
 {'affected_calibres': '300 AAC BLACKOUT',
  'frn': 196232,
  'legal_authority': 87,
  'make': 'Q',
  'model': 'Honey Badger'},
 {'affected_calibres': '300 AAC BLACKOUT',
  'frn': 196233,
  'legal_authority': 87,
  'make': 'Q',
  'model': 'Sugar Weasel'},
 {'affected_calibres': '10MM AUTO, 45 AUTO',
  'frn': 158929,
  'legal_authority': 87,
  'make': 'Quartercircle10',
  'model': 'GLF'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 129426,
  'legal_authority': 87,
  'make': 'R&C Armoury',
  'model': 'AR15'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 144539,
  'legal_authority': 87,
  'make': 'RGuns',
  'model': 'TRR15 Pistol'},
 {'affected_calibres': '22 LR',
  'frn': 196207,
  'legal_authority': 87,
  'make': 'Radian',
  'model': 'AX556'},
 {'affected_calibres': '300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 186482,
  'legal_authority': 87,
  'make': 'Radical Firearms',
  'model': 'RF-15 Pistol'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 172502,
  'legal_authority': 87,
  'make': 'Radical Firearms',
  'model': 'RFS-15 Pistol'},
 {'affected_calibres': '410 GA X 3"',
  'frn': 179042,
  'legal_authority': 87,
  'make': 'Ranger',
  'model': 'XT3 Tactical'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 126166,
  'legal_authority': 87,
  'make': 'Remington',
  'model': 'XM110'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 134481,
  'legal_authority': 87,
  'make': 'Rock River Arms',
  'model': 'LAR-PPS'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 34192,
  'legal_authority': 96,
  'make': 'Ruger',
  'model': 'No 1'},
 {'affected_calibres': '308 WIN, 7.62MM NATO',
  'frn': 145724,
  'legal_authority': 87,
  'make': 'Ruger',
  'model': 'SR762'},
 {'affected_calibres': '243 WIN, 308 WIN',
  'frn': 1891,
  'legal_authority': 88,
  'make': 'Ruger',
  'model': 'XGI'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 170742,
  'legal_authority': 87,
  'make': 'S F Armory',
  'model': 'RDR-MK1'},
 {'affected_calibres': '223 REM',
  'frn': 127790,
  'legal_authority': 83,
  'make': 'SIG',
  'model': 'SG552 SP'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 22629,
  'legal_authority': 83,
  'make': 'SIG',
  'model': 'Stgw90PE'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 128736,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'P556'},
 {'affected_calibres': '22 LR',
  'frn': 133868,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'SIG 22SCM'},
 {'affected_calibres': '22 LR',
  'frn': 129147,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'SIG 522'},
 {'affected_calibres': '22 LR',
  'frn': 132171,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'SIG 522 Pistol'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 133870,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'SIG 556'},
 {'affected_calibres': '7.62X39 RUSSIAN',
  'frn': 138116,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'SIG 556R'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 139846,
  'legal_authority': 83,
  'make': 'SIG Sauer',
  'model': 'SIG 556xi'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 125338,
  'legal_authority': 83,
  'make': 'SIGARMS',
  'model': 'SIG 556'},
 {'affected_calibres': '223 REM',
  'frn': 128667,
  'legal_authority': 87,
  'make': 'SOG Armory',
  'model': 'SOG Tactical'},
 {'affected_calibres': '308 WIN',
  'frn': 161966,
  'legal_authority': 89,
  'make': 'Savminter Enterprises',
  'model': 'Victor'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 120064,
  'legal_authority': 96,
  'make': 'Schuerman Arms',
  'model': 'SA40'},
 {'affected_calibres': '700 NITRO EXPRESS',
  'frn': 122289,
  'legal_authority': 96,
  'make': 'Searcy, B',
  'model': 'Double Barrel Rifle'},
 {'affected_calibres': '223 REM, 300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 193662,
  'legal_authority': 87,
  'make': 'Sharps Bros',
  'model': 'Hellbreaker'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 185082,
  'legal_authority': 87,
  'make': 'Smith & Wesson',
  'model': 'M&P 15 Competition'},
 {'affected_calibres': '5.45X39 RUSSIAN',
  'frn': 130443,
  'legal_authority': 87,
  'make': 'Smith & Wesson',
  'model': 'M&P 15R'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 135377,
  'legal_authority': 87,
  'make': 'Smith & Wesson',
  'model': 'M&P 15TS'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 130447,
  'legal_authority': 87,
  'make': 'Smith & Wesson',
  'model': 'M&P 15VTAC'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 130451,
  'legal_authority': 87,
  'make': 'Smith & Wesson',
  'model': 'M&P 15X'},
 {'affected_calibres': '223 REM, 5.56MM NATO',
  'frn': 128273,
  'legal_authority': 87,
  'make': 'Socom Firearms Corporation',
  'model': 'Diplomat'},
 {'affected_calibres': '307 WIN, 308 WIN, 7.62MM NATO',
  'frn': 128270,
  'legal_authority': 87,
  'make': 'Socom Firearms Corporation',
  'model': 'Leonidas AR10'},
 {'affected_calibres': '223 REM, 9MM LUGER, 9X21 IMI',
  'frn': 128272,
  'legal_authority': 87,
  'make': 'Socom Firearms Corporation',
  'model': 'Stubby ARC'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 154726,
  'legal_authority': 87,
  'make': 'Specialized Tactical Systems',
  'model': 'SX3'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 154686,
  'legal_authority': 87,
  'make': 'Specialized Tactical Systems',
  'model': 'SX3 Zombie Slayer Limited Edition'},
 {'affected_calibres': '700 NITRO EXPRESS',
  'frn': 25204,
  'legal_authority': 96,
  'make': 'Spendal',
  'model': 'Double Rifle'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 180002,
  'legal_authority': 87,
  'make': "Spike's Tactical",
  'model': 'Meanstreak'},
 {'affected_calibres': '22 LR, 223 REM',
  'frn': 130869,
  'legal_authority': 87,
  'make': "Spike's Tactical",
  'model': 'SLP-15 Pistol'},
 {'affected_calibres': '223 REM, 300 AAC BLACKOUT, 5.56MM NATO',
  'frn': 189742,
  'legal_authority': 87,
  'make': 'Springfield Armory',
  'model': 'Saint Pistol'},
 {'affected_calibres': '308 WIN',
  'frn': 196082,
  'legal_authority': 87,
  'make': 'Springfield Armory',
  'model': 'Saint Victor'},
 {'affected_calibres': '223 REM',
  'frn': 142624,
  'legal_authority': 87,
  'make': 'Sterling Cross Defence Systems',
  'model': 'Wartak Reaper MARK 1'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 131107,
  'legal_authority': 96,
  'make': 'Stillers Precision Firearms',
  'model': 'TAC408'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 131168,
  'legal_authority': 96,
  'make': 'Stillers Precision Firearms',
  'model': 'TAC408'},
 {'affected_calibres': '300 AAC BLACKOUT',
  'frn': 194805,
  'legal_authority': 87,
  'make': 'Stoner',
  'model': 'SR-30 SBR'},
 {'affected_calibres': '700 SSS',
  'frn': 86838,
  'legal_authority': 96,
  'make': 'Szecsei & Fuchs',
  'model': 'SSS'},
 {'affected_calibres': '4 GA',
  'frn': 126363,
  'legal_authority': 95,
  'make': 'TOZ',
  'model': 'TOZ-123-01'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 133417,
  'legal_authority': 87,
  'make': 'Thor',
  'model': 'TR15 Carbine'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 133105,
  'legal_authority': 96,
  'make': 'Thor Global Defense Group',
  'model': '408 XM Series'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 137416,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': '2010'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164328,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'G7'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164330,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'G9'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164366,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'PA22'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164335,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'SA15'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164331,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'SA22'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164383,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'SAG33'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164336,
  'legal_authority': 87,
  'make': 'Tomahawk',
  'model': 'SO2'},
 {'affected_calibres': '9MM LUGER',
  'frn': 169222,
  'legal_authority': 87,
  'make': 'Tresna Defense',
  'model': 'Tresna JAG9G'},
 {'affected_calibres': '9MM LUGER',
  'frn': 169442,
  'legal_authority': 87,
  'make': 'Tresna Defense',
  'model': 'Tresna JAG9G'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 195057,
  'legal_authority': 87,
  'make': 'TriStar',
  'model': 'KRX'},
 {'affected_calibres': '9MM LUGER',
  'frn': 154814,
  'legal_authority': 87,
  'make': 'Troy Defense',
  'model': 'Troy M5'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 147633,
  'legal_authority': 87,
  'make': 'Troy Defense',
  'model': 'Troy M7A1 Carbine'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 147583,
  'legal_authority': 87,
  'make': 'Troy Defense',
  'model': 'Troy Proctor Carbine'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 147605,
  'legal_authority': 87,
  'make': 'Troy Defense',
  'model': 'Troy Rifle'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 178102,
  'legal_authority': 87,
  'make': 'Troy Defense',
  'model': 'Troy Rifle M10A1'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 176624,
  'legal_authority': 87,
  'make': 'Typhoon Defence',
  'model': 'F12 Typhoon'},
 {'affected_calibres': '223 REM',
  'frn': 130085,
  'legal_authority': 87,
  'make': 'Unknown',
  'model': 'AR15'},
 {'affected_calibres': '7.62MM NATO',
  'frn': 195954,
  'legal_authority': 89,
  'make': 'Unknown',
  'model': 'M305'},
 {'affected_calibres': '4 GA',
  'frn': 67425,
  'legal_authority': 95,
  'make': 'Unknown',
  'model': 'Side By Side'},
 {'affected_calibres': '4 GA, 4 GA PERCUSSION',
  'frn': 72015,
  'legal_authority': 95,
  'make': 'Unknown',
  'model': 'Single Barrel'},
 {'affected_calibres': '50, 50 BMG',
  'frn': 83771,
  'legal_authority': 96,
  'make': 'Unknown',
  'model': 'Sporter'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 164286,
  'legal_authority': 87,
  'make': 'Uzkon',
  'model': 'BR99'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 169003,
  'legal_authority': 87,
  'make': 'Uzkon',
  'model': 'TR199'},
 {'affected_calibres': '375 CHEYENNE TACTICAL, 408 CHEYENNE TACTICAL',
  'frn': 145119,
  'legal_authority': 96,
  'make': 'Vigilance Rifles',
  'model': 'VR1'},
 {'affected_calibres': '5.56MM NATO, 50 BEOWULF, 7.62X39 RUSSIAN',
  'frn': 136602,
  'legal_authority': 87,
  'make': 'Vltor Weapon Systems',
  'model': 'VR'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 120096,
  'legal_authority': 96,
  'make': 'Voere',
  'model': '2002XXL'},
 {'affected_calibres': '408 CHEYENNE TACTICAL',
  'frn': 194565,
  'legal_authority': 96,
  'make': 'Voere',
  'model': 'X3'},
 {'affected_calibres': '5.56MM NATO',
  'frn': 157852,
  'legal_authority': 87,
  'make': 'War Sport',
  'model': 'GPR'},
 {'affected_calibres': '223 REM',
  'frn': 151333,
  'legal_authority': 87,
  'make': 'War Sport',
  'model': 'LVOA'},
 {'affected_calibres': '223 REM',
  'frn': 156886,
  'legal_authority': 87,
  'make': 'War Sport',
  'model': 'LVOA Pistol'},
 {'affected_calibres': '12 GA X 3"',
  'frn': 167042,
  'legal_authority': 87,
  'make': 'Warrior',
  'model': 'Warrior Tactical Shotgun'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 30152,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'Classic MARK 1'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 30162,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'Classic MARK 2'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 35211,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 35213,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 30129,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Crown Custom'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 32475,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Custom'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 32477,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Custom'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 117595,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V DGR'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 3018,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Deluxe'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 3079,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Deluxe'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 39531,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Deluxe'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 3074,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Euromark'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 29900,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Euromark'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 3036,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Europa'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 3080,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Lazermark'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 29914,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Lazermark'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 30130,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Safari Custom'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 32514,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Safari Custom'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 32516,
  'legal_authority': 96,
  'make': 'Weatherby',
  'model': 'MARK V Safari Custom'},
 {'affected_calibres': '4 GA',
  'frn': 27754,
  'legal_authority': 95,
  'make': 'Webley & Scott',
  'model': 'Wild Fowl Gun'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 134296,
  'legal_authority': 96,
  'make': 'Wells & Wells',
  'model': 'Mauser Magnum Sporter'},
 {'affected_calibres': '4 GA',
  'frn': 82751,
  'legal_authority': 95,
  'make': 'Westley Richards',
  'model': 'Single Barrel'},
 {'affected_calibres': '600 X 3"',
  'frn': 57898,
  'legal_authority': 96,
  'make': 'Westley Richards',
  'model': 'Single Under-Lever Rifle'},
 {'affected_calibres': '223 REM, 300 AAC BLACKOUT, 450 BUSHMASTER, 7.62X39 RUSSIAN',
  'frn': 191282,
  'legal_authority': 87,
  'make': 'Windham Weaponry',
  'model': 'WW-PS'},
 {'affected_calibres': '223 REM',
  'frn': 128653,
  'legal_authority': 87,
  'make': 'ZM Weapons',
  'model': 'LR300AXLT'},
 {'affected_calibres': '600 NITRO EXPRESS, 700 NITRO EXPRESS',
  'frn': 60657,
  'legal_authority': 96,
  'make': 'Zanardini',
  'model': 'Bristol 409 Express'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 60617,
  'legal_authority': 96,
  'make': 'Zanardini',
  'model': 'Oxford 403SL Express'},
 {'affected_calibres': '600 NITRO EXPRESS',
  'frn': 60604,
  'legal_authority': 96,
  'make': 'Zanardini',
  'model': 'Oxford 407SL Express'},
 {'affected_calibres': '460 WBY MAG',
  'frn': 77171,
  'legal_authority': 96,
  'make': 'Casartelli, Carlo',
  'model': 'Africa'}]


// Set apiConnector in search-ui config
config.apiConnector = connector
config.onSearch = (state, queryConfig, next) => {
      const updatedState = removeValidDate(state);
      return next(updatedState, queryConfig);
    }

// Define styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: 36,
  },
  table: {
    minWidth: 650,
  },
  frtTableContainer: {
    marginTop: 24,
  },
  mobileMessage: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  }
}));

// HomePage componenet
export default function FirearmsMore() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({
        wasSearched,
        isLoading }) => ({
          wasSearched,
          isLoading })}>
        {({
          wasSearched,
          isLoading }) => {

          // Flag for loading and no prior search
          var initial_loader = (isLoading && !wasSearched) ? true : false;

          // Reducer for SiteInfo component
          const [stateSI, dispatchSI] = useReducer(reducer, initialState.siteInfo);

          // Set styles
          const classes = useStyles();

          return (
            <div className="App">
            <ErrorBoundary>
              <Header dispatchSI={ dispatchSI } disableSearch={ true }/>
              <Layout
                header={
                  <>
                    <Authenticator hideDefault={true}>
                      <LayoutHeader dispatchSI={ dispatchSI } pageName="RCMP Prohibited Firearms"/>
                    </Authenticator>
                    <div className={classes.frtTableContainer}>
                      <h2>RCMP Prohibited Firearms</h2>
                      <Typography variant="body1" component="h3" gutterBottom>The following table is a list of firearms in the public Firearms Reference Table that cite amendments made in SOR/2020-96 as a legal authority for being Prohibited but are not explicitly named in SOR/2020-96.</Typography>
                      <Typography variant="subtitle2" component="h4" className={classes.mobileMessage}>Scroll to the right for more table columns.</Typography>
                      <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} stickyHeader  aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>FRN</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Make</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Model</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Affected Calibres</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Legal Authority (PFR, Part 1, para.)</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(frts.length > 0) ? frts.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell component="th" scope="row"><Typography variant="body2" className={classes.tableRow}>{row.frn}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.make}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.model}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.affected_calibres}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.legal_authority}</Typography></TableCell>
                              </TableRow>
                            )) : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                  </>
                }
                sideContent={
                  null
                }
                bodyContent={
                  null
                }
                bodyHeader={
                  null
                }
                bodyFooter={null}
              />
            </ErrorBoundary>
              <Footer dispatchSI={ dispatchSI } />
              <SiteInfo stateSI={ stateSI } dispatchSI={ dispatchSI } />
              { (!initial_loader && wasSearched) && <ScrollToTopButton /> }
              <Helmet>
                <title>Canadian Firearms Data & Analytics | Armalytics</title>
              </Helmet>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
