// Sort options
export default [
  {
    name: "Relevance",
    value: "",
    direction: ""
  },
  {
    name: "FRN",
    value: "frn",
    direction: "asc"
  },
  {
    name: "Model",
    value: "model",
    direction: "asc"
  },
  {
    name: "Make",
    value: "make",
    direction: "asc"
  }
];
