/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React from "react";
import { AuthPiece } from "aws-amplify-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import armTheme from "../styles/armTheme";

// Import logo
import logo from "../assets/logo_dark.png";

// Styles
const styles = makeStyles(theme => ({
  container: {
    marginTop: "15%",
    marginBottom: "32px",
    marginLeft: "15px",
    marginRight: "15px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "5%",
    },
  },
  link: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      textDecoration: "none",
      margin: "0 auto 0px auto",
      width: "290px",
  },
  logoimg: {
    height: "50px",
    width: "84.13px",
    marginRight: "8px",
  },
  logotext: {
    color: armTheme.colours.primary.main,
    fontFamily: "Black Ops One, Roboto",
  },
  messageContainer: {
    marginTop: "24px",
  },
  message: {
    textAlign: "center",
    maxWidth: "500px",
    margin: "0 auto"
  },
  title: {
    fontWeight: 500
  },
  subtitle: {
    color: armTheme.colours.faded.main,
    fontWeight: 500
  },
  tagline: {
    color: armTheme.colours.primary.main,
  },
}));

// States to apply Style A
const styleAStates = ["signIn", "signUp"];

function Banner({authState}) {
  const classes = styles();

  return (
    <div className={classes.container}>
      <Link to="/" className={classes.link} color="inherit">
        <img className={classes.logoimg} src={logo} alt="Logo" />
        <Typography className={classes.logotext} variant="h4" component="h1">Armalytics</Typography>
      </Link>
      <div className={classes.messageContainer}>
        <div className={classes.message}>
          <Typography className={classes.title} variant="h5" component="h2" gutterBottom>Explore Canada&apos;s Firearms Reference Table</Typography>
          {styleAStates.includes(authState) && (
              <Typography variant="subtitle1" component="h3" className={classes.subtitle} gutterBottom>Sign-up and get full access to information on over 40,000 firearms and their variants.</Typography>
            )
          }
        </div>
      </div>
    </div>
  );
}

Banner.propTypes = {
  authState: PropTypes.string,
};

export default Banner;

// <Typography className={classes.tagline} variant="subtitle2" component="h3" gutterBottom><em>Made by firearms owners.</em></Typography>
