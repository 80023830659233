import React from "react";
import PropTypes from "prop-types";
import Result from "./Result";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

// Loader component
import { DisappearedLoading } from "react-loadingg";

// Styles
const useStyles = makeStyles({
  root: {
    marginTop: "32px"
  },
  result: {
    width: "100%",
    marginBottom: "32px"
  },
});

function SearchBodyContent({ results, initialLoader }) {
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      {initialLoader && <DisappearedLoading color="#9a0606"/>}
      {!initialLoader && (
        results.map((result, i) => (
          <div key={i} className={classes.result}>
            <Result result={result} />
          </div>
        ))
      )}
    </div>
  );
}

export default SearchBodyContent;

SearchBodyContent.propTypes = {
    results: PropTypes.array,
    initialLoader: PropTypes.bool,
};






