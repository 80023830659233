import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

// SearchUI components
import { PagingInfo, ResultsPerPage } from "@elastic/react-search-ui";

function SearchBodyHeader({ wasSearched, initial_loader }) {
  return (
    !initial_loader && (
      <React.Fragment>
        {wasSearched && <PagingInfo
                          view={({ start, end, searchTerm, totalResults }) => (
                            <Typography variant="caption" display="block" >
                              Showing&nbsp;
                              <strong>
                                {start} - {end}
                              </strong>
                              {(totalResults < 10000) ? <span>&nbsp;out of&nbsp;</span> : <span>&nbsp;out of over&nbsp;</span>}

                              <strong>
                                {totalResults}
                              </strong>
                              {
                                searchTerm &&
                                  <span>
                                    &nbsp;for:&nbsp;
                                    <em>
                                      {searchTerm}
                                    </em>
                                  </span>
                              }
                            </Typography>
                          )}
                        />
                      }
        {wasSearched && <ResultsPerPage options={[10, 25, 50]} />}
      </React.Fragment>
    )
  );
}

export default SearchBodyHeader;

SearchBodyHeader.propTypes = {
    wasSearched: PropTypes.any,
    initial_loader: PropTypes.bool,
};
