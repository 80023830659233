import React from "react";
import { Link } from "react-router-dom";
import Banner from "./Banner";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// Styles
const styles = makeStyles({
  container: {
    margin: "50px 15px",
    display: "block",
    textAlign: "center",
  },
  subtitle1: {
    // fontWeight: 400,
  },
  subtitle2: {
    maxWidth: "500px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "5%",
    fontWeight: 400,
  }
});

const fuddLore = [
  "Make sure to let the gun cool off between clips so the rounds don't cook off.",
  "If the temperature's below zero stagger hollow-point and full metal jacket.",
  "Don't worry about bad groups at close range, the projectile will settle out by 300 yards.",
  "Nobody needs more than 8 rounds.",
  "Listen here son, you need a .45 ACP for real stopping power.",
  "Polymer guns are prone to heat warping.",
  "You can't use a 5.56, it'll ruin the meat.",
  "AR15s are a paperclip away from full auto."
];

function NotFoundPage() {
  const classes = styles();

  var index = Math.floor(Math.random() * fuddLore.length)
  var lore = fuddLore[index]

  index += (3 * Math.floor(Math.random() * fuddLore.length) + 1)

  return (
    <div>
      <Banner authState="notFound" />
      <div className={classes.container}>
        <Typography variant="h4" component="h3" gutterBottom className={classes.subtitle1}>Page Not Found</Typography>
        <Typography variant="h6" component="h4" gutterBottom className={classes.subtitle2}>Fudd Lore #{index}: &quot;{lore}&quot;</Typography>
        <Link to="/">
          <Button
            variant="contained"
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Go Home
          </Button>
        </Link>
      </div>
    </div>
  );
}

export default NotFoundPage;
