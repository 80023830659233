/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React from "react";
import { AuthPiece } from "aws-amplify-react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";

// Table component setup
const styles = theme => ({
  backSplash: {
    zIndex: -1,
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#f2f2f2",
  },
});

class BackSplash extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signUp", "signedUp", "confirmSignUp", "forgotPassword",  "requireNewPassword"];
  }

  showComponent(theme) {
    const { classes } = this.props;
    return (
      <div className={classes.backSplash}></div>
    );
  }
}

BackSplash.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(BackSplash);
