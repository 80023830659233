/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React from "react";
import { AuthPiece } from "aws-amplify-react";
import PropTypes from "prop-types";
import Greetings from "./Greetings";
import Auth from "@aws-amplify/auth";

class GreetingsContainer extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signedIn"];
    this.signOut = this.signOut.bind(this);
  }
  
  signOut() {
    Auth.signOut();
  }
  
  showComponent(theme) {
    return (
      <div>
        <Greetings name={this.props.authData.attributes.name} signOut={this.signOut}/>
      </div>
    );
  }
}

GreetingsContainer.propTypes = {
  children: PropTypes.element,
};

export default GreetingsContainer;