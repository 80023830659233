/* eslint-disable import/no-named-as-default */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { hot } from "react-hot-loader";

// Local components
import App from "./App";
import armTheme from "../styles/armTheme";

// Amplify components
import aws_exports from "../aws-exports";
import Amplify from "@aws-amplify/core";
Amplify.configure(aws_exports);

 // Material UI components
 import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";

 // Material UI theme
 const themeUI = createMuiTheme({
   palette: {
     primary: {
       main: armTheme.colours.primary.main,
     },
     secondary: {
       main: armTheme.colours.secondary.main,
     }
   },
 });

class Root extends Component {
  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={themeUI}>
            <App />
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default hot(module)(Root);
