// HomePage.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Authenticator } from 'aws-amplify-react';

// Material UI components
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from "@material-ui/core/styles";

// Local components
import ExpansionPanelSummaryContent from "./ExpansionPanelSummaryContent";
import ExpansionPanelDetailsContent from "./ExpansionPanelDetailsContent";
import ResultToolbar from "./ResultToolbar";

// Styles
const useStyles = makeStyles({
  panelDetails: {
    borderTop: "1px solid #777777",
  },
  divider: {
    height: "80px",
  },
});

// Amplify theme overrides
const ampTheme = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}

export default function Result({ result }) {
  const classes = useStyles();
  const [expand, setExpand] = useState(false);

  return (
    <ExpansionPanel 
      TransitionProps={{ unmountOnExit: true }}
      onChange={(event, expanded) => setExpand(expanded)}
    >
      <ExpansionPanelSummary
        expandIcon={null}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ExpansionPanelSummaryContent result={result} />
        <Divider orientation="vertical" flexItem variant="middle" className={classes.divider}/>
        <Authenticator hideDefault={true} theme={ampTheme} >
          <ResultToolbar expand={expand} result={result} />
        </Authenticator>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.panelDetails}>
        <ExpansionPanelDetailsContent result={result} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

Result.propTypes = {
    result: PropTypes.object,
};
