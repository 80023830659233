/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Link } from "react-router-dom";
import HomePage from "./HomePage";
import FirearmsMore from "./FirearmsMore";
import FirearmsMoreRisk from "./FirearmsMoreRisk";
import FirearmsG4 from "./FirearmsG4";
import AuthPage from "./AuthPage";
import NotFoundPage from "./NotFoundPage";

class App extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <div>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/firearms-potentially-affected-by-amendment-g4" component={FirearmsG4} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  children: PropTypes.element
};

export default App;

// <Route exact path="/oic-additional-firearms" component={FirearmsMore} />
// <Route exact path="/rcmp-prohibited-firearms" component={FirearmsMore} />
// <Route exact path="/auth" component={AuthPage} />
// <Route path='/firearms-potentially-affected-by-amendment-g4' component={() => {
//      window.location.href = 'https://armalytics-public.s3.amazonaws.com/frt-lists/Firearms+Potentially+Affected+by+Amendment+G4.xlsx';
//      return null;
// }}/>
