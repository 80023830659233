// HomePage.js
import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";

// Reducer objects
import { CHANGE_SITE_INFO } from "../constants/actionTypes";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  crumbsStart: {
    color: "rgba(0, 0, 0, 0.54)",
  },
  toolbar: {
    minHeight: 0,
    [theme.breakpoints.down("xs")]: {
      marginTop: "16px",
      justifyContent: "space-between",
      width: "100%",
    },
  },
  buttons: {
    marginLeft: "16px",
  },
}));

function LayoutHeader({ dispatchSI, authState, pageName}) {
  const classes = useStyles();

  const goToDonate = () => {
    var newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = "https://donorbox.org/support-armalytics";
  };

  const handleItemSelect = () => {
    dispatchSI({type: CHANGE_SITE_INFO, payload: {open: true, page: "whySignUp"}});
  }

  return (
    <div id="back-to-top-anchor" className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        <Typography className={classes.crumbsStart} color="textPrimary" component="h1">Home</Typography>
        <Typography color="textPrimary" component="h1">{ pageName }</Typography>
      </Breadcrumbs>

      <Toolbar className={classes.toolbar} disableGutters={true} >
        <Button
          size="small"
          className={classes.buttons}
          variant="outlined"
          color="primary"
          onClick={goToDonate}
        >
          Donate
        </Button>
      </Toolbar>
    </div>
  );
}

LayoutHeader.propTypes = {
    dispatchSI: PropTypes.func,
    authState: PropTypes.string,
    pageName: PropTypes.string,
};

export default LayoutHeader;
