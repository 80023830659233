/* eslint-disable import/no-named-as-default */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

// Local components
import armTheme from "../styles/armTheme";
import AuthHeader from "./AuthHeader";
import BackSplash from "./BackSplash";
import AuthRedirect from "./AuthRedirect";

// Amplify components
import {
  Authenticator,
  SignUp,
  ConfirmSignUp,
  RequireNewPassword,
  ForgotPassword,
  SignIn
} from "aws-amplify-react";

// Amplify theme overrides
const ampTheme = {
  a: {
    color: armTheme.colours.primary.main,
  },
  button: {
      backgroundColor: armTheme.colours.primary.main,
  },
  formContainer: {
    marginTop: "0px",
    marginLeft: "15px",
    marginRight: "15px",
  },
  navButton: {
    backgroundColor: "transparent",
    color: armTheme.colours.primary.main,
    fontSize: armTheme.fontSizes.p.small,
    minWidth: "0px",
    padding: "6px 0px",
    fontWeight: 600,
    textDecoration: "underline",
  },
  navBar: {
    border: "none",
  },
  nav: {
    padding: "10px 24px",
  },
  navItem: {
    fontSize: armTheme.fontSizes.p.small,
    lineHeight: "14px",
    padding: "6px 5px",
  },
  toast : {
    position: "fixed",
  },
}

// Amplify sign-up component overrides
const signUpConfig = {
  hideAllDefaults: true,
  signUpFields: [
    {
      label: "Nickname",
      key: "name",
      placeholder: "Enter your nickname",
      required: true,
      displayOrder: 1,
      type: "text"
    },
    {
      label: "Username",
      key: "username",
      placeholder: "Enter your email address",
      required: true,
      displayOrder: 2,
      type: "email"
    },
    {
      label: "Password",
      key: "password",
      placeholder: "Enter your password",
      required: true,
      displayOrder: 3,
      type: "password"
    }
  ]
};

class AuthPage extends Component {
  render() {
    const initialAuthState = !this.props.location ?
      "signIn" : !this.props.location.state ?
          "signIn" : !this.props.location.state.authState ?
            "signIn" : this.props.location.state.authState;

    const map = (message) => {
        if (/custom.*auth.*lambda/i.test(message)) {
          return 'Please enter a username and password';
        } else if (/user.*does.*not.*exist/i.test(message)) {
          return 'User does not exist. Please sign in using your email.';
        } else if (/password.*conform.*policy/i.test(message)) {
          return 'Password requires: uppercase characters, lowercase characters, and numbers.';
        }
        return message;
    };

    return (
      <>
        <Authenticator errorMessage={map} authState={initialAuthState} hideDefault={true} onStateChange={authState => {
          this.setState({ authState })
        }} theme={ampTheme}>
          <BackSplash/>
          <AuthHeader/>
          <SignIn/>
          <SignUp signUpConfig={ signUpConfig }/>
          <ConfirmSignUp/>
          <RequireNewPassword/>
          <ForgotPassword/>
          <AuthRedirect/>
        </Authenticator>
        <Helmet>
          <title>Sign In | Armalytics</title>
        </Helmet>
      </>
    );
  }
}

AuthPage.propTypes = {
  children: PropTypes.element,
  location: PropTypes.object,
};

export default AuthPage;
