// HomePage.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import Storage from "@aws-amplify/storage";
import { Auth } from "@aws-amplify/auth";
import { Link } from "react-router-dom";

// Material UI components
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import OutlinedFlagRoundedIcon from "@material-ui/icons/OutlinedFlagRounded";
import Tooltip from "@material-ui/core/Tooltip";

// Snackbar
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";

// Styles
const useStyles = makeStyles({
  toolbar: {
    flexDirection: "column",
  },
});

function ResultToolbar({ result, expand, authState }) {
  const classes = useStyles();

  // Set states
  const [open, setOpen] = useState(false); // Snackbar open state
  const [message, setMessage] = useState(""); // Snackbar message state
  const [sbBtn, setSbBtn] = useState(false); // Snackbar message button state

  function handleSnack(m, b) {
    setMessage(m);
    setSbBtn(b);
    setOpen(true);
  }

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // PDF download information
  const frn = result.frn.raw;
  const vd = result.valid_date.raw;
  const s3Key = `${vd}/frn-${frn}_valid-${vd}.pdf`
  function downloadPDF() {
    // if (authState !== "signedIn"){
    //   handleSnack("Please sign in to download.", true)
    // } else {
      handleSnack("Fetching PDF...", false)

      Auth.currentCredentials().then((creds) => {
        Storage.list(s3Key, { level: "public", credentials: Auth.essentialCredentials(creds) })
        .then(flist => {
          if (flist.length == 0) {
            handleSnack("File not found. Please contact us for support.", false)
          } else {
            Storage.get(s3Key, { level: "public", expires: 180, credentials: Auth.essentialCredentials(creds) })
            .then(url => {
              // Create a dummy anchor to simulate click for download
              const dummyLink = document.createElement("a");
              dummyLink.href = url;
              document.body.appendChild(dummyLink);
              dummyLink.click();
              document.body.removeChild(dummyLink);
              setTimeout(() => {
                setOpen(false);
              }, 1000);
            })
            .catch(() => handleSnack("An error occured. Please contact us for support.", false));
          }
        })
        .catch(() => handleSnack("An error occured. Please contact us for support.", false));
      })


    // }
  }


  return (
      <>
        <Toolbar
          disableGutters={true}
          className={ classes.toolbar }
          onClick={event => event.stopPropagation()}
          onFocus={event => event.stopPropagation()}
        >
          <IconButton
            size="small"
            color="default"
            onClick={() => downloadPDF()}
            >
              <Tooltip title="Download PDF">
                <GetAppIcon fontSize="small" />
              </Tooltip>
          </IconButton>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={6000}
            onClose={handleSnackClose}
            message={message}
            action={
              <>
                {sbBtn &&
                  <Button color="secondary" size="small" onClick={handleSnackClose} component={Link} to="/auth">
                    SIGN IN
                  </Button>
                }
                <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </>
            }
          />
        </Toolbar>
        <IconButton
          size="small"
          color="default"
        >
          {expand ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
        </IconButton>
      </>
  );
}

ResultToolbar.propTypes = {
    result: PropTypes.object,
    expand: PropTypes.bool,
    authState: PropTypes.string,
};

export default ResultToolbar;

// <Tooltip title="Get Notifications">
//   <IconButton
//     size="small"
//     color="default"
//     onClick={() => handleSnack("Get Notifications feature coming soon!", false)}
//     >
//       <NotificationsNoneIcon fontSize="small" />
//   </IconButton>
// </Tooltip>
// <Tooltip title="Report Issue">
//   <IconButton
//     size="small"
//     color="default"
//     onClick={() => handleSnack("Report Issue feature coming soon!", false)}>
//     <OutlinedFlagRoundedIcon fontSize="small" />
//   </IconButton>
// </Tooltip>
