import React from "react";
import PropTypes from "prop-types";

// Material UI Components
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: "16px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  button: {
    textDecoration: "underline",
    color: "#ffffff",
    marginLeft: "8px",
  }
}));

function Greetings({ name, signOut}) {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <Typography variant="body2" noWrap>
          Hello {name}!
        </Typography>
        <Button className={classes.button} onClick={() => signOut()}>Sign Out</Button>
      </div>
  );
}

export default Greetings;

Greetings.propTypes = {
    name: PropTypes.string,
    signOut: PropTypes.func,
};
