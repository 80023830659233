/* eslint-disable import/no-named-as-default */
import React from "react";
import { Route, Redirect } from "react-router"
import PropTypes from "prop-types";

function AuthRedirect({ authState }) {
  return (
    <>
      { (authState === "signedIn") &&
        <Route exact path="/auth" render={() => (<Redirect to={{pathName: "/"}}/>)}/>
      }
    </>
  );
}

AuthRedirect.propTypes = {
  authState: PropTypes.string,
};

export default AuthRedirect;
