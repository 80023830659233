// HomePage.js
import React, { useReducer } from "react";
import { Authenticator } from "aws-amplify-react";
import { Helmet } from "react-helmet";

// Local components
import Header from "./Header";
import LayoutHeader from "./LayoutHeader";
import SearchSideContent from "./SearchSideContent";
import SearchBodyHeader from "./SearchBodyHeader";
import SiteInfo from "./SiteInfo";
import Footer from "./Footer";
import SearchBodyContent from "./SearchBodyContent";
import ScrollToTopButton from "./ScrollToTopButton";

// Reducer objects
import reducer from "../reducers/siteInfoReducer"
import initialState from "../reducers/initialState"

// SearchUI components
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  SearchProvider,
  Paging,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import config from "../searchui/SearchConfig";
require("@elastic/react-search-ui-views/lib/styles/styles.css");

// Create new Elastic App Search connection instance
const connector = new AppSearchAPIConnector({
  searchKey: "search-2f7pnod8kxxe8vo7733wk7su",
  engineName: "frt",
  endpointBase: "https://frtv2.ent.ca-central-1.aws.elastic-cloud.com"
});

// Remove valid_date filter helper function
function removeValidDate(state) {
  if ("filters" in state) {
    state["filters"] = state["filters"].filter(x => x["field"] != "valid_date");
  }
  return state;
}

// Set apiConnector in search-ui config
config.apiConnector = connector
config.onSearch = (state, queryConfig, next) => {
      const updatedState = removeValidDate(state);
      return next(updatedState, queryConfig);
    }

// HomePage componenet
export default function HomePage() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({
        wasSearched,
        results,
        totalResults,
        resultsPerPage,
        isLoading }) => ({
          wasSearched,
          results,
          totalResults,
          resultsPerPage,
          isLoading })}>
        {({
          wasSearched,
          results,
          totalResults,
          resultsPerPage,
          isLoading }) => {

          // Total pages to use in Paging component
          var totalPagesCap = (Math.ceil(totalResults / resultsPerPage) < 100) ? Math.ceil(totalResults / resultsPerPage) : 100;

          // Flag for loading and no prior search
          var initial_loader = (isLoading && !wasSearched) ? true : false;

          // Reducer for SiteInfo component
          const [stateSI, dispatchSI] = useReducer(reducer, initialState.siteInfo);

          return (
            <div className="App">
              <ErrorBoundary>
                <Header dispatchSI={ dispatchSI } disableSearch={ false }/>
                <Layout
                  header={
                    <Authenticator hideDefault={true}>
                      <LayoutHeader dispatchSI={ dispatchSI } pageName="Firearms Reference Table"/>
                    </Authenticator>
                  }
                  sideContent={
                    <SearchSideContent initial_loader={ initial_loader } wasSearched={ wasSearched }/>
                  }
                  bodyContent={
                    <SearchBodyContent initialLoader={ initial_loader } results={ results } />
                  }
                  bodyHeader={
                    <SearchBodyHeader initial_loader={ initial_loader } wasSearched={ wasSearched } />
                  }
                  bodyFooter={!initial_loader && (<Paging totalPages={totalPagesCap} />)}
                />
              </ErrorBoundary>
              <Footer dispatchSI={ dispatchSI } />
              <SiteInfo stateSI={ stateSI } dispatchSI={ dispatchSI } />
              { (!initial_loader && wasSearched) && <ScrollToTopButton /> }
              <Helmet>
                <title>Canadian Firearms Data & Analytics | Armalytics</title>
              </Helmet>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
