/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React, { useReducer } from "react";

// Material UI components
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

function PrivacyPolicy() {
    return (
      <>
        <DialogTitle id="alert-dialog-title">Armalytics - Privacy Policy</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h2>Privacy Policy</h2>

            <p>Last updated: March 22, 2020</p>

            <p>This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.</p>

            <p>We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.</p>

            <h3>Summary</h3>
            <p>Armalytics is a non-commercial project that will not sell any data collected on our service to third-parties. Armalytics will only share with select third-parties the data which is absolutely necessary to run our service.</p>
            <p>Armalytics uses the following third-party services: Simple Analytics and MailChimp. Simple Analytics is used to monitor network traffic to the site and does not use cookies nor does it collect any personal data. MailChimp allows us to manage and send emails to our users.</p>
            <p>Armalytics will ask to collect Your email address, and nickname in order to sign-in/sign-up and use our authenticated services. We may also track some usage data at an anonymous level to better understand how the site is being used and to improve our services.</p>
            <p>Armalytics uses your browser&apos;s local storage for authenticating users, and for keeping track of user settings (such as language preference).</p>

            <p>We encourage the community to offer their suggestions and work with us to ensure that our policy meets the privacy expectations of all our users.</p>

            <p>If you have any questions or feedback about this Privacy Policy, You can contact us:</p>
            <ul>
                 <li>By email: info@armalytics.ca</li>
            </ul>

            <p>Please read on for further details.</p>

            <h2>Interpretation and Definitions</h2>
            <h3>Interpretation</h3>
            <p>The words of which the initial letter is capitalized have meanings defined under the following conditions.</p>
            <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>

            <h3>Definitions</h3>
            <p>For the purposes of this Privacy Policy:</p>
            <ul>
             <li><p><strong>You</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</p></li>
             <li><p><strong>Company</strong> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this Agreement) refers to Armalytics.</p></li>
             <li><p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party, where &quot;control&quot; means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</p></li>
             <li><p><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</p></li>
             <li><p><strong>Website</strong> refers to Armalytics, accessible from https://www.armalytics.ca</p></li>
             <li><p><strong>Service</strong> refers to the Website.</p></li>
             <li><p><strong>Country</strong> refers to: Ontario, Canada</p></li>
             <li><p><strong>Service Provider</strong> means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</p></li>
             <li><p><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</p></li>
             <li><p><strong>Local Storage Data</strong> is any application data stored locally within the user&apos;s browser using the &quot;localStorage&quot; object.</p></li>
             <li><p><strong>Usage Data</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p></li>
            </ul>

            <h2>Collecting and Using Your Personal Data</h2>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information includes:</p>
            <ul>
             <li>Email address</li>
             <li>Nickname</li>
             <li>Username</li>
             <li>Usage Data</li>
            </ul>
            <h4>Usage Data</h4>
            <p>Usage Data is collected automatically when using the Service.</p>
            <p>Usage Data may include information such as Your Device&apos;s browser type, the pages of our Service that You visit (this does not include query parameters at the end of URLs), the time and date of Your visit, the time spent on those pages, and other diagnostic data.</p>
            <p>When You access the Service by or through a mobile device, We may collect certain information automatically, including, the type of mobile Internet browser You use, and other diagnostic data.</p>
            <p>We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
            <h4>Local Storage Data</h4>
            <p>You can instruct Your browser to refuse all Local Storage data. However, if You do not accept Local Storage data, You may not be able to use some parts of our Service.</p>
            <p>Local Storage Data is &quot;Persistent&quot;. Persistent Local Storage Data remains on your personal computer or mobile device when You go offline.</p>
              <p>We use Local Storage Data for the purposes set out below:</p>
            <ul>
               <li>
                   <p><strong>Functionality Data</strong></p>
                   <p>Type: Persistent Data</p>
                   <p>Administered by: Us</p>
                   <p>Purpose: This Local Storage Data allows us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these objects are to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</p>
               </li>
             </ul>

            <h3>Use of Your Personal Data</h3>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
             <li><strong>To provide and maintain our Service</strong>, including to monitor the usage of our Service.</li>
             <li><strong>To manage Your Account:</strong> to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</li>
             <li><strong>To contact You:</strong> To contact You by email regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</li>
             <li><strong>To provide You</strong> with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</li>
             <li><strong>To manage Your requests:</strong> To attend and manage Your requests to Us.</li>
            </ul>
            <p>Below outlines how The Company will <strong>not</strong> use your Personal Data:</p>
            <ul>
             <li><strong>For profit:</strong> The Company will not sell any of the data collected through our Service to anyone.</li>
             <li><strong>Distribution to third-parties:</strong> The Company will not distribute your Personal Data to any third-parties expect for the ways detailed in this Policy which are necessary to run the Service.</li>
            </ul>

            <p>We may share your personal information in the following situations:</p>
            <ul>
             <li><strong>With Service Providers:</strong> We may share Your personal information with the Service Providers outlined in this Privacy Policy in the way outlined in this Privacy Policy.</li>
             <li><strong>With other users:</strong> when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. Similarly, on an opt-in basis, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
            </ul>

            <h3>Retention of Your Personal Data</h3>
            <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
            <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

            <h3>Transfer of Your Personal Data</h3>
            <p>Your information, including Personal Data, is processed at the Company&apos;s operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.</p>
            <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
            <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>

            <h3>Disclosure of Your Personal Data</h3>
            <h4>Business Transactions</h4>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred only after you agree to the new Privacy Policy, otherwise Your Personal Data will be purged from our systems. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
            <h4>Law enforcement</h4>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
            <h4>Other legal requirements</h4>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
             <li>Comply with a legal obligation</li>
             <li>Protect and defend the rights or property of the Company</li>
             <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
             <li>Protect the personal safety of Users of the Service or the public</li>
             <li>Protect against legal liability</li>
            </ul>

            <h3>Security of Your Personal Data</h3>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>

            <h2>Detailed Information on the Processing of Your Personal Data</h2>
            <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.</p>

            <h3>Analytics</h3>
            <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>
            <ul>
                 <li>
                 <p><strong>Simple Analytics</strong></p>
                 <p>Simple Analytics is a web analytics service that tracks and reports website traffic. Simple Analytics uses the data collected to track and monitor the use of our Service. Simple Analytics does not use cookies nor does it collect any personal data.</p>
                 <p>For more information on the privacy practices of Simple Analytics, please visit their Privacy policy: <a href="https://simpleanalytics.com/privacy" target="_blank" rel="noopener noreferrer">https://simpleanalytics.com/privacy</a>.</p>
                 <p>You can opt-out of having made your activity on the Service available to Simple Analytics by enabling the <em>Do Not Track</em> setting in your browser.</p>
             </li>
            </ul>
            <h3>Email Marketing</h3>
            <p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.</p>
            <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>
             <ul>
                 <li>
                 <p><strong>Mailchimp</strong></p>
                 <p>Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.</p>
                 <p>For more information on the privacy practices of Mailchimp, please visit their Privacy policy: <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer">https://mailchimp.com/legal/privacy/</a></p>
             </li>
                     </ul>
            <h2>Children&apos;s Privacy</h2>
            <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
            <p>We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these users.</p>
            <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent&apos;s consent before We collect and use that information.</p>


            <h2>Links to Other Websites</h2>
            <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party&apos;s site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

            <h2>Changes to this Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>
            <ul>
                 <li>By email: info@armalytics.ca</li>
            </ul>
          </DialogContentText>
        </DialogContent>
      </>
    );
}

export default PrivacyPolicy;
