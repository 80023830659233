// HomePage.js
import React from "react";
import PropTypes from "prop-types";

// Local components
import Note from "./Note";
// import AuthRequired from "./AuthRequired";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Table component setup
const useStyles = makeStyles({
  root: {
    marginTop: "12px",
    width: "100%",
  },
  section: {
    marginBottom: "20px",
  },
  tableContainer: {
    maxHeight: 450,
  },
  table: {
    minWidth: 650,
  },
  sumHeader: {
    fontWeight: 500,
  },
});

export default function ExpansionPanelDetailsContent({ result }) {
  const classes = useStyles();

  // Pre-process data
  var calibres = (result.calibre_shots_barrel_length.raw ? result.calibre_shots_barrel_length.raw.map(x => JSON.parse(x)) : []);
  var notes = JSON.parse(result.notes.raw);
  var refs = (result.cross_references.raw ? result.cross_references.raw.map(x => JSON.parse(x)) : []);
  var prods = (result.also_known_as_product_code.raw ? result.also_known_as_product_code.raw : []);
  var years = (result.year_dates.raw ? result.year_dates.raw : []);
  var imports = (result.importer.raw ? result.importer.raw : []);

  return (
    <div className={classes.root}>
      <div>
        <Typography variant="h6" component="h4" gutterBottom>Summary</Typography>
            <ul>
                <li><Typography variant="body2"><span className={classes.sumHeader}>Firearm Reference Number (FRN):</span> <span>{result.frn.raw}</span></Typography></li>
                <li><Typography variant="body2"><span className={classes.sumHeader}>Valid As Of Date:</span> <span>{result.valid_date.raw}</span></Typography></li>
                <li><Typography variant="body2"><span className={classes.sumHeader}>Manufacturer:</span> <span>{result.manufacturer.raw}</span></Typography></li>
                <li><Typography variant="body2"><span className={classes.sumHeader}>Level:</span> <span>{result.level.raw}</span></Typography></li>
                <li><Typography variant="body2"><span className={classes.sumHeader}>Country of Manufacturer:</span> <span>{result.country_of_manufacturer.raw}</span></Typography></li>
                <li><Typography variant="body2"><span className={classes.sumHeader}>Serial Numbering:</span> <span>{result.serial_numbering.raw}</span></Typography></li>
            </ul>

      </div>
      <div className={classes.section}>
          <Typography variant="h6" component="h4" gutterBottom>Calibre, Shots and Barrel Length</Typography>

            {calibres.length > 0 ? (
              <TableContainer className={classes.tableContainer} component={Paper}>
                <Table className={classes.table} stickyHeader size="small" aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>FRN</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Calibre</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Shots</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Barrel&nbsp;(mm)</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Class</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Legal Authority</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Level</Typography></TableCell>
                      <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Barrel Type Code</Typography></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(calibres.length > 0) ? calibres.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row"><Typography variant="body2" className={classes.tableRow}>{row.frn}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.calibre}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.shots}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.barrel_mm}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.legal_classification}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.legal_authority}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.level}</Typography></TableCell>
                        <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.barrel_type_code}</Typography></TableCell>
                      </TableRow>
                    )) : null}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : <Typography variant="body2">No Data</Typography>}

      </div>
      <div>
          <Typography variant="h6" component="h4" gutterBottom>Notes</Typography>
          {Object.prototype.hasOwnProperty.call(notes, "make") ? <Note heading="Make" value={notes.make} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "model") ? <Note heading="Model" value={notes.model} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "manufacturer") ? <Note heading="Manufacturer" value={notes.manufacturer} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "type") ? <Note heading="Type" value={notes.type} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "action") ? <Note heading="Action" value={notes.action} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "calibre") ? <Note heading="Calibre" value={notes.calibre} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "shots") ? <Note heading="Shots" value={notes.shots} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "barrel") ? <Note heading="Barrel" value={notes.barrel} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "serial_number") ? <Note heading="Serial Number" value={notes.serial_number} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "canadian_law_comments") ? <Note heading="Canadian Law Comments" value={notes.canadian_law_comments} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "remarks") ? <Note heading="Remarks" value={notes.remarks} /> : null }
          {Object.prototype.hasOwnProperty.call(notes, "other_markings") ? <Note heading="Other Markings" value={notes.other_markings} /> : null }
      </div>
      <div className={classes.section}>
          <Typography variant="h6" component="h4" gutterBottom>Cross-References</Typography>
          {refs.length > 0 ? (
            <TableContainer className={classes.tableContainer} component={Paper}>
              <Table className={classes.table} stickyHeader size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>FRN</Typography></TableCell>
                    <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Make</Typography></TableCell>
                    <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Model</Typography></TableCell>
                    <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Manufacturer</Typography></TableCell>
                    <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Type</Typography></TableCell>
                    <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Action</Typography></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(refs.length > 0) ? refs.map((row, i) => (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row"><Typography variant="body2" className={classes.tableRow}>{row.frn}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.make}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.model}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.manufacturer}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.type}</Typography></TableCell>
                      <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.action}</Typography></TableCell>
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </TableContainer>
          ) : <Typography variant="body2">No Data</Typography>}
      </div>
      {prods.length > 0 ? (
        <div>
          <Typography variant="h6" component="h4" gutterBottom>Also Known As/Product Code</Typography>
          <ul>
            {prods.map((v, i) => (<li key={i}><Typography variant="body2">{v}</Typography></li>))}
          </ul>
        </div>
      ) : null}
      {years.length > 0 ? (
        <div>
          <Typography variant="h6" component="h4" gutterBottom>Year Dates</Typography>
          <ul>
            {years.map((v, i) => (<li key={i}><Typography variant="body2">{v}</Typography></li>))}
          </ul>
        </div>
      ) : null}
      {imports.length > 0 ? (
        <div>
          <Typography variant="h6" component="h4" gutterBottom>Importer</Typography>
          <ul>

              {imports.map((v, i) => (<li key={i}><Typography variant="body2">{v}</Typography></li>))}

          </ul>
        </div>
      ) : null}
    </div>
  );
}

ExpansionPanelDetailsContent.propTypes = {
    result: PropTypes.object
};
