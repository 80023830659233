// HomePage.js
import React, { useReducer } from "react";
import { Authenticator } from "aws-amplify-react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

// Local components
import Header from "./Header";
import LayoutHeader from "./LayoutHeader";
import SiteInfo from "./SiteInfo";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";

// Reducer objects
import reducer from "../reducers/siteInfoReducer"
import initialState from "../reducers/initialState"

// SearchUI components
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import config from "../searchui/SearchConfig";
require("@elastic/react-search-ui-views/lib/styles/styles.css");

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Create new Elastic App Search connection instance
const connector = new AppSearchAPIConnector({
  searchKey: "search-2f7pnod8kxxe8vo7733wk7su",
  engineName: "frt",
  endpointBase: "https://frtv2.ent.ca-central-1.aws.elastic-cloud.com"
});

// Remove valid_date filter helper function
function removeValidDate(state) {
  if ("filters" in state) {
    state["filters"] = state["filters"].filter(x => x["field"] != "valid_date");
  }
  return state;
}

// Additional FRTs
const frts = [{'action': 'Bolt Action',
  'frn': 166822,
  'legal_classification': 'Non-Restricted',
  'make': 'Adler',
  'model': 'Adler B210',
  'type': 'Shotgun',
  'url': '?q=Adler%20Adler%20B210&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Air, Spring or Gas',
  'frn': 43218,
  'legal_classification': 'Non-Restricted',
  'make': 'Airrow',
  'model': 'A-8SRB Stealth',
  'type': 'Rifle',
  'url': '?q=Airrow%20A-8SRB%20Stealth&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 132450,
  'legal_classification': 'Non-Restricted',
  'make': 'Akdal',
  'model': 'MKA 1919',
  'type': 'Shotgun',
  'url': '?q=Akdal%20MKA%201919&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 142124,
  'legal_classification': 'Non-Restricted',
  'make': 'Akdal',
  'model': 'MKA 1919',
  'type': 'Shotgun',
  'url': '?q=Akdal%20MKA%201919&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 145170,
  'legal_classification': 'Non-Restricted',
  'make': 'Akdal',
  'model': 'TAC-12',
  'type': 'Shotgun',
  'url': '?q=Akdal%20TAC-12&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 149826,
  'legal_classification': 'Non-Restricted',
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Hunter',
  'type': 'Rifle',
  'url': '?q=Alberta%20Tactical%20Rifle%20Modern%20Hunter&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 146190,
  'legal_classification': 'Non-Restricted',
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Hunter Prototype',
  'type': 'Rifle',
  'url': '?q=Alberta%20Tactical%20Rifle%20Modern%20Hunter%20Prototype&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 194622,
  'legal_classification': 'Non-Restricted',
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Sporter',
  'type': 'Rifle',
  'url': '?q=Alberta%20Tactical%20Rifle%20Modern%20Sporter&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 162446,
  'legal_classification': 'Non-Restricted',
  'make': 'Alberta Tactical Rifle',
  'model': 'Modern Varmint',
  'type': 'Rifle',
  'url': '?q=Alberta%20Tactical%20Rifle%20Modern%20Varmint&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 147547,
  'legal_classification': 'Non-Restricted',
  'make': 'Alexander Arms',
  'model': 'Ulfberht',
  'type': 'Rifle',
  'url': '?q=Alexander%20Arms%20Ulfberht&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 142275,
  'legal_classification': 'Non-Restricted',
  'make': 'Alpharms',
  'model': '07SA',
  'type': 'Shotgun',
  'url': '?q=Alpharms%2007SA&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 147046,
  'legal_classification': 'Non-Restricted',
  'make': 'Alpharms',
  'model': '15SA',
  'type': 'Shotgun',
  'url': '?q=Alpharms%2015SA&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 3733,
  'legal_classification': 'Non-Restricted',
  'make': 'Armscor',
  'model': 'M1600',
  'type': 'Rifle',
  'url': '?q=Armscor%20M1600&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 3741,
  'legal_classification': 'Non-Restricted',
  'make': 'Armscor',
  'model': 'M1600R',
  'type': 'Rifle',
  'url': '?q=Armscor%20M1600R&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 158366,
  'legal_classification': 'Non-Restricted',
  'make': 'Asena',
  'model': 'Asena',
  'type': 'Shotgun',
  'url': '?q=Asena%20Asena&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 170762,
  'legal_classification': 'Non-Restricted',
  'make': 'Axor',
  'model': 'MF-1',
  'type': 'Shotgun',
  'url': '?q=Axor%20MF-1&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 170342,
  'legal_classification': 'Non-Restricted',
  'make': 'Axor',
  'model': 'MF-2',
  'type': 'Shotgun',
  'url': '?q=Axor%20MF-2&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 58978,
  'legal_classification': 'Non-Restricted',
  'make': 'Balikli',
  'model': 'Makarov BS-12',
  'type': 'Shotgun',
  'url': '?q=Balikli%20Makarov%20BS-12&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 23791,
  'legal_classification': 'Non-Restricted',
  'make': 'Balikli',
  'model': 'Makarov BSM-12',
  'type': 'Shotgun',
  'url': '?q=Balikli%20Makarov%20BSM-12&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 184002,
  'legal_classification': 'Non-Restricted',
  'make': 'Balikli',
  'model': 'Makarov S-12',
  'type': 'Shotgun',
  'url': '?q=Balikli%20Makarov%20S-12&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 166942,
  'legal_classification': 'Non-Restricted',
  'make': 'Berika',
  'model': 'TA1950',
  'type': 'Shotgun',
  'url': '?q=Berika%20TA1950&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 167043,
  'legal_classification': 'Non-Restricted',
  'make': 'Berika',
  'model': 'TS1950',
  'type': 'Shotgun',
  'url': '?q=Berika%20TS1950&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 145288,
  'legal_classification': 'Non-Restricted',
  'make': 'Bora Arms',
  'model': 'BR99',
  'type': 'Shotgun',
  'url': '?q=Bora%20Arms%20BR99&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 41308,
  'legal_classification': 'Non-Restricted',
  'make': 'Bushmaster',
  'model': 'M17S',
  'type': 'Rifle',
  'url': '?q=Bushmaster%20M17S&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Pump Action',
  'frn': 187342,
  'legal_classification': 'Non-Restricted',
  'make': 'Canuck',
  'model': 'Havoc',
  'type': 'Shotgun',
  'url': '?q=Canuck%20Havoc&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Bolt Action',
  'frn': 142982,
  'legal_classification': 'Non-Restricted',
  'make': 'Caracal',
  'model': 'CSR',
  'type': 'Rifle',
  'url': '?q=Caracal%20CSR&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Air, Spring or Gas',
  'frn': 138483,
  'legal_classification': 'Non-Restricted',
  'make': 'Crosman',
  'model': 'M4 Pneumatic Air Rifle',
  'type': 'Rifle',
  'url': '?q=Crosman%20M4%20Pneumatic%20Air%20Rifle&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Air, Spring or Gas',
  'frn': 143469,
  'legal_classification': 'Non-Restricted',
  'make': 'Crosman',
  'model': 'MTR77 NP',
  'type': 'Rifle',
  'url': '?q=Crosman%20MTR77%20NP&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 148680,
  'legal_classification': 'Non-Restricted',
  'make': 'Derya Arms',
  'model': 'Anakon MC-1980',
  'type': 'Shotgun',
  'url': '?q=Derya%20Arms%20Anakon%20MC-1980&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 141298,
  'legal_classification': 'Non-Restricted',
  'make': 'Derya Arms',
  'model': 'Lion X4',
  'type': 'Shotgun',
  'url': '?q=Derya%20Arms%20Lion%20X4&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 141381,
  'legal_classification': 'Non-Restricted',
  'make': 'Derya Arms',
  'model': 'MK10',
  'type': 'Shotgun',
  'url': '?q=Derya%20Arms%20MK10&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 186562,
  'legal_classification': 'Non-Restricted',
  'make': 'Derya Arms',
  'model': 'MK10 Gen 2',
  'type': 'Shotgun',
  'url': '?q=Derya%20Arms%20MK10%20Gen%202&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 141379,
  'legal_classification': 'Non-Restricted',
  'make': 'Derya Arms',
  'model': 'MK12',
  'type': 'Shotgun',
  'url': '?q=Derya%20Arms%20MK12&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 164268,
  'legal_classification': 'Non-Restricted',
  'make': 'Derya Arms',
  'model': 'VR90',
  'type': 'Shotgun',
  'url': '?q=Derya%20Arms%20VR90&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 146487,
  'legal_classification': 'Non-Restricted',
  'make': 'Excel Arms',
  'model': 'X-22R',
  'type': 'Rifle',
  'url': '?q=Excel%20Arms%20X-22R&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Pump Action',
  'frn': 122850,
  'legal_classification': 'Non-Restricted',
  'make': 'FN',
  'model': 'Tactical Police Shotgun',
  'type': 'Shotgun',
  'url': '?q=FN%20Tactical%20Police%20Shotgun&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 157548,
  'legal_classification': 'Non-Restricted',
  'make': 'Husan & Eksen',
  'model': 'MKA 1919 Match',
  'type': 'Shotgun',
  'url': '?q=Husan%20%26%20Eksen%20MKA%201919%20Match&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 152887,
  'legal_classification': 'Non-Restricted',
  'make': 'Jard',
  'model': 'J17',
  'type': 'Rifle',
  'url': '?q=Jard%20J17&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 147862,
  'legal_classification': 'Non-Restricted',
  'make': 'Jard',
  'model': 'J48',
  'type': 'Rifle',
  'url': '?q=Jard%20J48&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Pump Action',
  'frn': 17360,
  'legal_classification': 'Non-Restricted',
  'make': 'Khan',
  'model': 'A-TAC PX Pro',
  'type': 'Shotgun',
  'url': '?q=Khan%20A-TAC%20PX%20Pro&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 191962,
  'legal_classification': 'Non-Restricted',
  'make': 'Kirici',
  'model': 'AXL 47',
  'type': 'Shotgun',
  'url': '?q=Kirici%20AXL%2047&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 153787,
  'legal_classification': 'Non-Restricted',
  'make': 'Kral',
  'model': 'KRX',
  'type': 'Shotgun',
  'url': '?q=Kral%20KRX&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Bolt Action',
  'frn': 20603,
  'legal_classification': 'Non-Restricted',
  'make': 'Lynx',
  'model': 'Presence 15',
  'type': 'Rifle',
  'url': '?q=Lynx%20Presence%2015&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 158626,
  'legal_classification': 'Non-Restricted',
  'make': 'MKA Arms',
  'model': 'MKA 1919',
  'type': 'Shotgun',
  'url': '?q=MKA%20Arms%20MKA%201919&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 181822,
  'legal_classification': 'Non-Restricted',
  'make': 'Maccabee Defense',
  'model': 'SLR-MULTI',
  'type': 'Rifle',
  'url': '?q=Maccabee%20Defense%20SLR-MULTI&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 133396,
  'legal_classification': 'Non-Restricted',
  'make': 'Mossberg',
  'model': '702 Plinkster Tactical 22',
  'type': 'Rifle',
  'url': '?q=Mossberg%20702%20Plinkster%20Tactical%2022&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 136698,
  'legal_classification': 'Non-Restricted',
  'make': 'Mossberg',
  'model': '715T Tactical 22',
  'type': 'Rifle',
  'url': '?q=Mossberg%20715T%20Tactical%2022&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 147718,
  'legal_classification': 'Non-Restricted',
  'make': 'Mossberg',
  'model': '715T Tactical 22 Duck Commander',
  'type': 'Rifle',
  'url': '?q=Mossberg%20715T%20Tactical%2022%20Duck%20Commander&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 185024,
  'legal_classification': 'Non-Restricted',
  'make': 'Pallas',
  'model': 'PX1',
  'type': 'Rifle',
  'url': '?q=Pallas%20PX1&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 185063,
  'legal_classification': 'Non-Restricted',
  'make': 'Pallas',
  'model': 'PX2',
  'type': 'Shotgun',
  'url': '?q=Pallas%20PX2&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 163926,
  'legal_classification': 'Non-Restricted',
  'make': 'Pardus',
  'model': 'SD',
  'type': 'Shotgun',
  'url': '?q=Pardus%20SD&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 179042,
  'legal_classification': 'Non-Restricted',
  'make': 'Ranger',
  'model': 'XT3 Tactical',
  'type': 'Shotgun',
  'url': '?q=Ranger%20XT3%20Tactical&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 // {'action': 'Semi-Automatic',
 //  'frn': 129480,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Remington',
 //  'model': '597VTR',
 //  'type': 'Rifle',
 //  'url': '?q=Remington%20597VTR&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 // {'action': 'Bolt Action',
 //  'frn': 145640,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Remington',
 //  'model': '700 MDT TAC21',
 //  'type': 'Rifle',
 //  'url': '?q=Remington%20700%20MDT%20TAC21&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 // {'action': 'Bolt Action',
 //  'frn': 127117,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Remington',
 //  'model': '700 XCR Tactical',
 //  'type': 'Rifle',
 //  'url': '?q=Remington%20700%20XCR%20Tactical&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 {'action': 'Semi-Automatic',
  'frn': 195283,
  'legal_classification': 'Non-Restricted',
  'make': 'Revolution Armory',
  'model': 'AXL 47',
  'type': 'Shotgun',
  'url': '?q=Revolution%20Armory%20AXL%2047&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 123588,
  'legal_classification': 'Non-Restricted',
  'make': 'Rhineland Arms',
  'model': 'R22',
  'type': 'Rifle',
  'url': '?q=Rhineland%20Arms%20R22&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 141963,
  'legal_classification': 'Non-Restricted',
  'make': 'Rock Island Armory',
  'model': 'M1600',
  'type': 'Rifle',
  'url': '?q=Rock%20Island%20Armory%20M1600&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 134204,
  'legal_classification': 'Non-Restricted',
  'make': 'Rock Island Armory',
  'model': 'MIG22',
  'type': 'Rifle',
  'url': '?q=Rock%20Island%20Armory%20MIG22&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 // {'action': 'Semi-Automatic',
 //  'frn': 169583,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Ruger',
 //  'model': '10/22 ATI AR',
 //  'type': 'Rifle',
 //  'url': '?q=Ruger%2010/22%20ATI%20AR&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 // {'action': 'Bolt Action',
 //  'frn': 159306,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Ruger',
 //  'model': 'Precision Rifle',
 //  'type': 'Rifle',
 //  'url': '?q=Ruger%20Precision%20Rifle&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 // {'action': 'Bolt Action',
 //  'frn': 184123,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Ruger',
 //  'model': 'Precision Rimfire',
 //  'type': 'Rifle',
 //  'url': '?q=Ruger%20Precision%20Rimfire&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 // {'action': 'Semi-Automatic',
 //  'frn': 129876,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Ruger',
 //  'model': 'SR22 Rifle',
 //  'type': 'Rifle',
 //  'url': '?q=Ruger%20SR22%20Rifle&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 {'action': 'Air, Spring or Gas',
  'frn': 173903,
  'legal_classification': 'Non-Restricted',
  'make': 'SIG Sauer',
  'model': 'SIG MCX',
  'type': 'Rifle',
  'url': '?q=SIG%20Sauer%20SIG%20MCX&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 139318,
  'legal_classification': 'Non-Restricted',
  'make': 'SRM Arms',
  'model': '12',
  'type': 'Shotgun',
  'url': '?q=SRM%20Arms%2012&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 129022,
  'legal_classification': 'Non-Restricted',
  'make': 'SRM Arms',
  'model': '1216',
  'type': 'Shotgun',
  'url': '?q=SRM%20Arms%201216&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Pump Action',
  'frn': 137022,
  'legal_classification': 'Non-Restricted',
  'make': 'Shooters Arms',
  'model': 'SAS12',
  'type': 'Shotgun',
  'url': '?q=Shooters%20Arms%20SAS12&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Bolt Action',
  'frn': 155327,
  'legal_classification': 'Non-Restricted',
  'make': 'Sirarms',
  'model': 'SIR40-24',
  'type': 'Rifle',
  'url': '?q=Sirarms%20SIR40-24&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 37827,
  'legal_classification': 'Non-Restricted',
  'make': 'Squires Bingham',
  'model': 'Concorde',
  'type': 'Rifle',
  'url': '?q=Squires%20Bingham%20Concorde&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 145433,
  'legal_classification': 'Non-Restricted',
  'make': 'TNW',
  'model': '1999 ASR',
  'type': 'Rifle',
  'url': '?q=TNW%201999%20ASR&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 147799,
  'legal_classification': 'Non-Restricted',
  'make': 'Thureon Defense',
  'model': 'GA',
  'type': 'Rifle',
  'url': '?q=Thureon%20Defense%20GA&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 137416,
  'legal_classification': 'Non-Restricted',
  'make': 'Tomahawk',
  'model': '2010',
  'type': 'Shotgun',
  'url': '?q=Tomahawk%202010&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 164327,
  'legal_classification': 'Non-Restricted',
  'make': 'Tomahawk',
  'model': 'G5',
  'type': 'Shotgun',
  'url': '?q=Tomahawk%20G5&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 164328,
  'legal_classification': 'Non-Restricted',
  'make': 'Tomahawk',
  'model': 'G7',
  'type': 'Shotgun',
  'url': '?q=Tomahawk%20G7&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 164330,
  'legal_classification': 'Non-Restricted',
  'make': 'Tomahawk',
  'model': 'G9',
  'type': 'Shotgun',
  'url': '?q=Tomahawk%20G9&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 164331,
  'legal_classification': 'Non-Restricted',
  'make': 'Tomahawk',
  'model': 'SA22',
  'type': 'Shotgun',
  'url': '?q=Tomahawk%20SA22&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 // {'action': 'Pump Action',
 //  'frn': 165142,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Troy Defense',
 //  'model': 'Troy International Sporting Rifle',
 //  'type': 'Rifle',
 //  'url': '?q=Troy%20Defense%20Troy%20International%20Sporting%20Rifle&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 {'action': 'Pump Action',
  'frn': 147688,
  'legal_classification': 'Non-Restricted',
  'make': 'Troy Defense',
  'model': 'Troy PAR',
  'type': 'Rifle',
  'url': '?q=Troy%20Defense%20Troy%20PAR&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Other',
  'frn': 187102,
  'legal_classification': 'Non-Restricted',
  'make': 'Troy Defense',
  'model': 'Troy SAR',
  'type': 'Rifle',
  'url': '?q=Troy%20Defense%20Troy%20SAR&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 // {'action': 'Pump Action',
 //  'frn': 147628,
 //  'legal_classification': 'Non-Restricted',
 //  'make': 'Troy Defense',
 //  'model': 'Troy Sporting Rifle',
 //  'type': 'Rifle',
 //  'url': '?q=Troy%20Defense%20Troy%20Sporting%20Rifle&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
 //  'confirmed_prohibited': 'No'},
 {'action': 'Semi-Automatic',
  'frn': 176624,
  'legal_classification': 'Non-Restricted',
  'make': 'Typhoon Defence',
  'model': 'F12 Typhoon',
  'type': 'Shotgun',
  'url': '?q=Typhoon%20Defence%20F12%20Typhoon&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 164286,
  'legal_classification': 'Non-Restricted',
  'make': 'Uzkon',
  'model': 'BR99',
  'type': 'Shotgun',
  'url': '?q=Uzkon%20BR99&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Semi-Automatic',
  'frn': 169002,
  'legal_classification': 'Non-Restricted',
  'make': 'Uzkon',
  'model': 'TR100',
  'type': 'Shotgun',
  'url': '?q=Uzkon%20TR100&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'},
 {'action': 'Semi-Automatic',
  'frn': 169003,
  'legal_classification': 'Non-Restricted',
  'make': 'Uzkon',
  'model': 'TR199',
  'type': 'Shotgun',
  'url': '?q=Uzkon%20TR199&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Yes'},
 {'action': 'Bolt Action',
  'frn': 194624,
  'legal_classification': 'Non-Restricted',
  'make': 'Voere',
  'model': 'S16',
  'type': 'Rifle',
  'url': '?q=Voere%20S16&filters%5B0%5D%5Bfield%5D=valid_date&filters%5B0%5D%5Bvalues%5D%5B0%5D=2020-05-06&filters%5B0%5D%5Btype%5D=all',
  'confirmed_prohibited': 'Safe'}]


// Set apiConnector in search-ui config
config.apiConnector = connector
config.onSearch = (state, queryConfig, next) => {
      const updatedState = removeValidDate(state);
      return next(updatedState, queryConfig);
    }

// Define styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: 36,
  },
  table: {
    minWidth: 650,
  },
  frtTableContainer: {
    marginTop: 24,
  },
  mobileMessage: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  selected: {
    backgroundColor: "rgba(255, 174, 66, 0.18) !important",
  },
}));

// FirearmsMoreRisk componenet
export default function FirearmsMoreRisk() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({
        wasSearched,
        isLoading }) => ({
          wasSearched,
          isLoading })}>
        {({
          wasSearched,
          isLoading }) => {

          // Flag for loading and no prior search
          var initial_loader = (isLoading && !wasSearched) ? true : false;

          // Reducer for SiteInfo component
          const [stateSI, dispatchSI] = useReducer(reducer, initialState.siteInfo);

          // Set styles
          const classes = useStyles();

          return (
            <div className="App">
            <ErrorBoundary>
              <Header dispatchSI={ dispatchSI } disableSearch={ true }/>
              <Layout
                header={
                  <>
                    <Authenticator hideDefault={true}>
                      <LayoutHeader dispatchSI={ dispatchSI } pageName="Paragraph 87 at Risk Firearms"/>
                    </Authenticator>
                    <div className={classes.frtTableContainer}>
                      <h2>Paragraph 87 at Risk Firearms</h2>
                      <Typography variant="body1" component="h3" gutterBottom>The following table lists firearms we believe are at risk of prohibition under paragraph 87 of the OIC: <br/><em>&quot;The firearms of the designs commonly known as the M16, AR-10 and AR-15 rifles and the M4 carbine, and any variants or modified versions of them.&quot;</em></Typography>
                      <Typography variant="body1" component="h3" gutterBottom>Firearms that mention &quot;AR-15&quot; or any variation thereof in their Model, Make, or Canadian Law Comments sections of the public FRT and have descriptions suggestive of an AR-15 style are deemed to be at risk.</Typography>
                      <Typography variant="body1" component="h3" gutterBottom><strong>This list was verified using the live FRT on May 18th, 2020.  Records have been updated accordingly.</strong></Typography>
                      <Typography variant="subtitle2" component="h4" className={classes.mobileMessage}><br/>Scroll to the right for more table columns.</Typography>
                      <TableContainer className={classes.tableContainer} component={Paper}>
                        <Table className={classes.table} stickyHeader  aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>FRN</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}> Legal Classification (Live FRT as of May 18th)</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Make</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Model</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Type</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Action</Typography></TableCell>
                              <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Link</Typography></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(frts.length > 0) ? frts.map((row, i) => (
                              <TableRow selected={row.confirmed_prohibited == "Yes" ? true : false} classes={{selected: classes.selected}} key={i}>
                                <TableCell component="th" scope="row"><Typography variant="body2" className={classes.tableRow}>{row.frn}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.confirmed_prohibited == "Yes" ? "Prohibited" : row.legal_classification}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.make}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.model}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.type}</Typography></TableCell>
                                <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.action}</Typography></TableCell>

                                <TableCell align="left">
                                  <Link to={`/${row.url}`} color="inherit" target="_blank">View FRT</Link>
                                </TableCell>
                              </TableRow>
                            )) : null}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>

                  </>
                }
                sideContent={
                  null
                }
                bodyContent={
                  null
                }
                bodyHeader={
                  null
                }
                bodyFooter={null}
              />
            </ErrorBoundary>
              <Footer dispatchSI={ dispatchSI } />
              <SiteInfo stateSI={ stateSI } dispatchSI={ dispatchSI } />
              { (!initial_loader && wasSearched) && <ScrollToTopButton /> }
              <Helmet>
                <title>Canadian Firearms Data & Analytics | Armalytics</title>
              </Helmet>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
