/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React from "react";
import { AuthPiece } from "aws-amplify-react";
import PropTypes from "prop-types";
import Banner from "./Banner";
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import armTheme from "../styles/armTheme";

// Styles
const styles = () => ({
  message: {
    textAlign: "center",
    maxWidth: "500px",
    margin: "0 auto"
  },
  subtitle: {
    color: armTheme.colours.faded.main,
    fontWeight: 600
  },
  subtitleB: {
    color: armTheme.colours.faded.main,
    fontWeight: 600,
    marginBottom: "1em"
  },
});

const styleBStates = ["signIn", "signedUp"];
const styleCStates = ["signIn", "signUp"];

class AuthHeader extends AuthPiece {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signUp", "signedUp", "confirmSignUp", "forgotPassword",  "requireNewPassword"];
  }

  showComponent(theme) {
    const { classes } = this.props;
    return (
      <>
        <Banner authState={this.props.authState}/>

        {styleBStates.includes(this.props.authState) && (
          <div className={classes.message}>
            <Typography variant="subtitle1" component="h3" className={classes.subtitle} gutterBottom>** Please use your email to sign in.</Typography>
          </div>
          )
        }

        {styleCStates.includes(this.props.authState) && (
          <div className={classes.message}>
            <Typography variant="subtitle2" component="h3" className={classes.subtitleB} gutterBottom>NOTE: Armalytics will be moving away from hosting user accounts to a newsletter only service. We appreciate your patience as we implement this change.</Typography>
          </div>
          )
        }
      </>
    );
  }
}

AuthHeader.propTypes = {
  children: PropTypes.element,
  classes: PropTypes.object,
};

export default withStyles(styles)(AuthHeader);
