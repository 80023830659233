/* eslint-disable import/no-named-as-default */
import React from 'react'

// Material UI Components
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

class SubscribePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        emailValue: '',
        nameValue: '',
    };
  }

    render() {
        return (
          <form action="https://armalytics.us19.list-manage.com/subscribe/post?u=15d2a23cddc84fefe33dd98f3&amp;id=c17915018b&amp;f_id=00368ce4f0" method="POST" noValidate>
            <div style={{display: "flex", justifyContents: 'left'}}>
              <div style={{padding: '0 8px'}}>
                <TextField
                  required
                  variant="outlined"
                  label="Email"
                  type="email"
                  name="EMAIL"
                  id="mce-EMAIL"
                  size="small"
                  value={this.state.emailValue}
                  onChange={ (e)=>{this.setState({emailValue: e.target.value});} }
                />
              </div>
              <div style={{padding: '0 8px'}}>
                <TextField
                  variant="outlined"
                  label="Nickname"
                  type="text"
                  name="NAME"
                  id="mce-NAME"
                  size="small"
                  value={this.state.nameValue}
                  onChange={ (e)=>{this.setState({nameValue: e.target.value});} }
                />
              </div>
              <div style={{padding: '0 8px'}}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"

                  >
                  Subscribe
                  <input hidden type="submit" value="subscribe" name="subscribe" id="mc-embedded-subscribe"/>
                </Button>
              </div>
            </div>
            <div style={{position: 'absolute', left: '-5000px'}} aria-hidden='true' aria-label="Please leave the following three fields empty">
              <input type="text" name="b_15d2a23cddc84fefe33dd98f3_c17915018b" tabIndex="-1" value="" id="b_name"/>
            </div>
          </form>
        )
    }
}

export default SubscribePage
