export default {
  colours: {
    primary: {
      main: "#9a0606",
    },
    secondary: {
      main: "#069a50",
    },
    faded: {
      main: "#5B5B5B",
    }
  },
  fontSizes: {
    p: {
      small: "12px",
    }
  }
  
};