// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:d6e0497c-c136-4ffd-bd85-cf9a0c9abb64",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_0hSX4SqWA",
    "aws_user_pools_web_client_id": "4trlnmgvafjmjml5j6m5atdocu",
    "oauth": {},
    "aws_content_delivery_bucket": "armalytics-prod-20200311031831-hostingbucket-armalytics",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://duworqxiov8ej.cloudfront.net",
    "aws_user_files_s3_bucket": "auth-usersarmalytics-armalytics",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
