// HomePage.js
import React, { useReducer } from "react";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Authenticator } from "aws-amplify-react";
import { Helmet } from "react-helmet";

// Local components
import Header from "./Header";
import LayoutHeader from "./LayoutHeader";
import SiteInfo from "./SiteInfo";
import Footer from "./Footer";
import ScrollToTopButton from "./ScrollToTopButton";

// Reducer objects
import reducer from "../reducers/siteInfoReducer"
import initialState from "../reducers/initialState"

// SearchUI components
import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import {
  ErrorBoundary,
  SearchProvider,
  WithSearch
} from "@elastic/react-search-ui";
import { Layout } from "@elastic/react-search-ui-views";
import config from "../searchui/SearchConfig";
require("@elastic/react-search-ui-views/lib/styles/styles.css");

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// Create new Elastic App Search connection instance
const connector = new AppSearchAPIConnector({
  searchKey: "search-2f7pnod8kxxe8vo7733wk7su",
  engineName: "frt",
  endpointBase: "https://frtv2.ent.ca-central-1.aws.elastic-cloud.com"
});

// Remove valid_date filter helper function
function removeValidDate(state) {
  if ("filters" in state) {
    state["filters"] = state["filters"].filter(x => x["field"] != "valid_date");
  }
  return state;
}

// Additional FRTs
const frts = [{'frn': 8,
  'manufacturer': 'International Business Machines (IBM)',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 9,
  'manufacturer': 'National Postal Meter',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 10,
  'manufacturer': 'Quality Hardware & Machine',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 12,
  'manufacturer': 'Rock-Ola Manufacturing',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 13,
  'manufacturer': 'Saginaw Steering Gear',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 14,
  'manufacturer': 'Standard Products Company',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 15,
  'manufacturer': 'Underwood-Elliot-Fisher',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 16,
  'manufacturer': 'Winchester',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 330,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'FN',
  'model': 'SAFN49',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 339,
  'manufacturer': 'Berlin-Lubecker Maschinenfabriken (duv)',
  'make': 'German Service Rifle',
  'model': 'K43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 482,
  'manufacturer': 'Harrington & Richardson',
  'make': 'Harrington & Richardson',
  'model': '60',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 512,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'GI-9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 520,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'PM30HB',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 521,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'M1 Paratrooper Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 526,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'M1 Survival Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 536,
  'manufacturer': 'Cranston Arms',
  'make': 'Johnson Automatics',
  'model': '1941',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 11},
 {'frn': 732,
  'manufacturer': 'Husqvarna',
  'make': 'Ljungmann',
  'model': 'AG42',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 733,
  'manufacturer': 'Carl Gustafs Stads Gevarsfaktori',
  'make': 'Ljungmann',
  'model': 'AG42B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 734,
  'manufacturer': 'Dansk Industri Syndikat',
  'make': 'Madsen',
  'model': 'Madsen-Ljungmann',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 735,
  'manufacturer': 'Husqvarna',
  'make': 'Ljungmann',
  'model': 'AG42B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 736,
  'manufacturer': 'Egyptian State Arsenals',
  'make': 'Ljungmann',
  'model': 'Hakim',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 739,
  'manufacturer': 'Mauser',
  'make': 'Mauser',
  'model': '1915 Automatic Rifle',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 1905,
  'manufacturer': 'Egyptian State Arsenals',
  'make': 'Egyptian Service Rifle',
  'model': 'Rashid',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2439,
  'manufacturer': 'Smith & Wesson',
  'make': 'Smith & Wesson',
  'model': 'M1940',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 2514,
  'manufacturer': 'Carl Gustafs Stads Gevarsfaktori',
  'make': 'Ljungmann',
  'model': 'AG42',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2537,
  'manufacturer': 'Russian State Arsenal, Izhevsk',
  'make': 'Tokarev',
  'model': 'SVT 1938',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2538,
  'manufacturer': '',
  'make': 'Tokarev',
  'model': 'SVT 1938',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2539,
  'manufacturer': 'Russian State Arsenal, Tula',
  'make': 'Tokarev',
  'model': 'SVT 1938',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2540,
  'manufacturer': 'Russian State Arsenal, Izhevsk',
  'make': 'Tokarev',
  'model': 'SVT 1940',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2542,
  'manufacturer': 'Russian State Arsenal, Tula',
  'make': 'Tokarev',
  'model': 'SVT 1940',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2568,
  'manufacturer': 'Gustloff-Werke (dfb)',
  'make': 'Volks Gewehr',
  'model': 'VG1-5',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 2570,
  'manufacturer': 'Ceskoslovenska Zbrojovka (she)',
  'make': 'CZ',
  'model': 'VZ52',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2700,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1905',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 2702,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1907 Police',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 4772,
  'manufacturer': 'Globe Firearms',
  'make': 'Globe Firearms',
  'model': 'Mohawk 555',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 5029,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK602',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 6},
 {'frn': 5031,
  'manufacturer': 'Ceskoslovenska Zbrojovka',
  'make': 'Holek, Emanuel',
  'model': 'Automat',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 15838,
  'manufacturer': 'Millville Ordnance Company (MOCO)',
  'make': 'MOCO',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 15},
 {'frn': 16341,
  'manufacturer': 'Alpine Industries',
  'make': 'Alpine',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 16342,
  'manufacturer': 'American Military Arms Corporation (AMAC)',
  'make': 'AMAC',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 19564,
  'manufacturer': 'Pietta',
  'make': 'Pietta',
  'model': 'Semi-Automatic',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 19655,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL6',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 20077,
  'manufacturer': 'Howa Machinery',
  'make': 'Howa',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 20270,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 20996,
  'manufacturer': 'Marlin Firearms',
  'make': 'Marlin',
  'model': '45',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 21190,
  'manufacturer': 'Kommando Arms Manufacturing',
  'make': 'Kommando Arms Manufacturing',
  'model': 'Paramax',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 21305,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 21473,
  'manufacturer': 'Kommando Arms Manufacturing',
  'make': 'Kommando Arms Manufacturing',
  'model': 'LDP',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 21475,
  'manufacturer': 'Kommando Arms Manufacturing',
  'make': 'Kommando Arms Manufacturing',
  'model': 'Kommando',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 21476,
  'manufacturer': 'Rhodesian State Arsenal',
  'make': 'Cobra',
  'model': 'Cobra',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 22632,
  'manufacturer': 'Springfield Armory',
  'make': 'Springfield Armory',
  'model': 'US Carbine M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 23247,
  'manufacturer': 'SAKO',
  'make': 'Valmet',
  'model': 'Hunter',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 23973,
  'manufacturer': 'Marlin Firearms',
  'make': 'Marlin',
  'model': '9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 24231,
  'manufacturer': 'Inland Manufacturing Division Of General Motors',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 24290,
  'manufacturer': 'National Ordnance',
  'make': 'National Ordnance',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 24443,
  'manufacturer': 'Peruvian State Arsenal, Sima Cefar',
  'make': 'Sima Cefar',
  'model': 'MGP14',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 32},
 {'frn': 24977,
  'manufacturer': 'SAKO',
  'make': 'Valmet',
  'model': 'M78',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 25002,
  'manufacturer': 'Franchi',
  'make': 'Franchi',
  'model': 'LF57 Police',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 40},
 {'frn': 25302,
  'manufacturer': 'Bullseye Gun Works',
  'make': 'Bullseye Gun Works',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 25679,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL7',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 25758,
  'manufacturer': 'Rock Island Armory',
  'make': 'Rock Island Armory',
  'model': 'US Carbine M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 26156,
  'manufacturer': 'Harrington & Richardson',
  'make': 'Reising',
  'model': '60',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 26409,
  'manufacturer': 'Springfield Armory',
  'make': 'Springfield Armory',
  'model': 'US Rifle M1 Garand Tanker',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 8},
 {'frn': 26473,
  'manufacturer': 'Sturm Ruger',
  'make': 'Ruger',
  'model': 'Police Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 15},
 {'frn': 26608,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 27046,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '30M-1BG',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27047,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': 'Ferret A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27202,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '30M-1B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27204,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '30M-1A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27205,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '30M-10S',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27206,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '30M-1BN',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27212,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1000',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27460,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '5000PT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 27462,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1005',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27464,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1010',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27466,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1015',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27468,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1020',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27471,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1025',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27476,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1004',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27477,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1941',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 27480,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1002',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 29960,
  'manufacturer': 'Ceskoslovenska Zbrojovka (she)',
  'make': 'CZ',
  'model': 'VZ52/57',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 31525,
  'manufacturer': 'Beemiller',
  'make': 'Hi-Point Firearms',
  'model': '995',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 31682,
  'manufacturer': 'Polish State Arsenal, Radom',
  'make': 'Polish State Arsenals',
  'model': 'PM84',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 31684,
  'manufacturer': 'Polish State Arsenal, Factory 11',
  'make': 'Polish State Arsenals',
  'model': 'PM84P',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 31850,
  'manufacturer': 'Berlin-Lubecker Maschinenfabriken (qve)',
  'make': 'German Service Rifle',
  'model': 'K43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 32518,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1907',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 32522,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1907 Fancy Sporting',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 34480,
  'manufacturer': 'MK Arms',
  'make': 'MK Arms',
  'model': 'MK760',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 36},
 {'frn': 36225,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1905 Fancy Sporting Rifle',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 37129,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'Sub-9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 41308,
  'manufacturer': 'Bushmaster Firearms',
  'make': 'Bushmaster',
  'model': 'M17S',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 44194,
  'manufacturer': 'Ohio Ordnance Works (OOW)',
  'make': 'Ohio Ordnance Works',
  'model': 'BAR M1918A3',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 44847,
  'manufacturer': 'Berlin-Lubecker Maschinenfabriken (duv)',
  'make': 'German Service Rifle',
  'model': 'G41',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 44905,
  'manufacturer': 'Mauser',
  'make': 'German Service Rifle',
  'model': 'G41(M)',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 45664,
  'manufacturer': 'Walther (ac)',
  'make': 'German Service Rifle',
  'model': 'G41(W)',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 45845,
  'manufacturer': 'Barrett Firearms',
  'make': 'Barrett Firearms',
  'model': '98',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 45863,
  'manufacturer': 'Walther (ac)',
  'make': 'German Service Rifle',
  'model': 'G41',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 45867,
  'manufacturer': 'Walther (ac)',
  'make': 'German Service Rifle',
  'model': 'G43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 46024,
  'manufacturer': 'Berlin-Lubecker Maschinenfabriken (duv)',
  'make': 'German Service Rifle',
  'model': 'G43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 46029,
  'manufacturer': 'Gustloff-Werke (bcd)',
  'make': 'German Service Rifle',
  'model': 'G43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 46870,
  'manufacturer': 'Walther (ac)',
  'make': 'German Service Rifle',
  'model': 'K43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 46985,
  'manufacturer': 'Gustloff-Werke (bcd)',
  'make': 'German Service Rifle',
  'model': 'K43',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 49450,
  'manufacturer': 'Irwin-Pedersen Arms',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 49453,
  'manufacturer': 'Inland Manufacturing Division Of General Motors',
  'make': 'US Carbine',
  'model': 'M1A1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 51306,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'Sub-40',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 52596,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK770',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 52917,
  'manufacturer': 'Zavodi Crvena Zastava',
  'make': 'Zastava',
  'model': 'M84 Skorpion',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 54185,
  'manufacturer': "Manufacture d'Armes de St-Etienne (MAS)",
  'make': 'MAS',
  'model': '1944',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 54186,
  'manufacturer': "Manufacture d'Armes de St-Etienne (MAS)",
  'make': 'MAS',
  'model': '1949',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 54187,
  'manufacturer': "Manufacture d'Armes de St-Etienne (MAS)",
  'make': 'MAS',
  'model': '1949/56',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 18},
 {'frn': 54485,
  'manufacturer': 'Russian State Arsenals',
  'make': 'Tokarev',
  'model': 'SVT 1940',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 54636,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL8-1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 56756,
  'manufacturer': 'Walther',
  'make': 'Walther',
  'model': 'WA2000',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 6},
 {'frn': 57598,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'G36KP',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 59397,
  'manufacturer': 'Robinson Armament (RA)',
  'make': 'Robinson Armament',
  'model': 'M96 Expeditionary Rifle',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 62084,
  'manufacturer': 'Breda',
  'make': 'Breda',
  'model': 'PG',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 62104,
  'manufacturer': 'Russian State Arsenals',
  'make': 'Tokarev',
  'model': 'SVT 1938',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 62106,
  'manufacturer': 'Ceskoslovenska Zbrojovka',
  'make': 'CZ',
  'model': 'ZH29',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 25},
 {'frn': 66064,
  'manufacturer': 'Israel Arms International (IAI)',
  'make': 'IAI',
  'model': 'M888 M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 66408,
  'manufacturer': 'Chinese State Arsenal, Factory 0101',
  'make': 'Chinese Service Rifle',
  'model': 'Type 68',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 66444,
  'manufacturer': 'Chinese State Arsenal, Factory 0101',
  'make': 'Chinese Service Rifle',
  'model': 'Type 63',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 66645,
  'manufacturer': 'Ceska Zbrojovka (CZ)',
  'make': 'CZ',
  'model': 'CZ91S',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 66925,
  'manufacturer': 'Hotchkiss',
  'make': 'Hotchkiss',
  'model': 'Universal',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 32},
 {'frn': 71015,
  'manufacturer': 'Sterling',
  'make': 'Sterling',
  'model': 'MARK 4 Police Carbine',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 34},
 {'frn': 71932,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'USC',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 75851,
  'manufacturer': '',
  'make': 'Eusta',
  'model': 'Eusta',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 77831,
  'manufacturer': 'Smith & Wesson',
  'make': 'Smith & Wesson',
  'model': 'M1940 MARK 2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 89455,
  'manufacturer': 'Valkyrie Arms',
  'make': 'Valkyrie Arms',
  'model': 'M3A1',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 91295,
  'manufacturer': 'Beretta',
  'make': 'Beretta',
  'model': '1918',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 91415,
  'manufacturer': 'Beretta',
  'make': 'Beretta',
  'model': '1918/30',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 91835,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Omega Weapons Systems Incorporated',
  'model': 'SPS-12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 97696,
  'manufacturer': 'Zbrojovka Brno',
  'make': 'Brno',
  'model': 'ZKM619',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 8},
 {'frn': 100295,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '07',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 104595,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL8',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 106196,
  'manufacturer': '',
  'make': 'US Carbine',
  'model': 'M1 Thai Carbine Rework',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 108915,
  'manufacturer': 'Smith & Wesson',
  'make': 'Smith & Wesson',
  'model': 'M1940 MARK 1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 117257,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'G36P',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 117283,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'UMP',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 117335,
  'manufacturer': 'Globe Firearms',
  'make': 'Globe Firearms',
  'model': '555A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 117357,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL8-4',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 117438,
  'manufacturer': 'Golden State Arms',
  'make': 'Santa Fe',
  'model': 'M59',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 117453,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'G36',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 117628,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SLB2000',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 118013,
  'manufacturer': 'Rhodesian State Arsenal',
  'make': 'Cobra',
  'model': 'MARK 1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 118033,
  'manufacturer': 'Ceskoslovenska Zbrojovka (tgf)',
  'make': 'CZ',
  'model': 'VZ52',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 118036,
  'manufacturer': 'Ceskoslovenska Zbrojovka (tgf)',
  'make': 'CZ',
  'model': 'VZ52/57',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 118426,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SAF SA',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 118622,
  'manufacturer': 'Howa Machinery',
  'make': 'Howa',
  'model': '300',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 118664,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'MINI SAF SA',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 118955,
  'manufacturer': 'Lucznik',
  'make': 'Lucznik',
  'model': 'BRS99',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 25},
 {'frn': 119424,
  'manufacturer': 'Howa Machinery',
  'make': 'Howa',
  'model': 'M1 Carbine Type',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 119555,
  'manufacturer': 'Armalite Incorporated',
  'make': 'Armalite',
  'model': 'AR-180B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 119611,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'Argo',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 119872,
  'manufacturer': 'Israel Military Industries (IMI)',
  'make': 'Tavor',
  'model': '21 TSR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 119922,
  'manufacturer': 'Firearms International Incorporated (fi)',
  'make': 'Firearms International Incorporated',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 120040,
  'manufacturer': 'Femaru Fegyver es Gepgyar RT (FEG)',
  'make': 'FEG',
  'model': 'KGPF',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 25},
 {'frn': 120053,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'Sub-2000',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 17},
 {'frn': 120476,
  'manufacturer': 'Rhodesian State Arsenal',
  'make': 'Cobra',
  'model': 'MARK 2 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 120819,
  'manufacturer': 'Manufacture de Machines du Haut Rhin (MANURHIN)',
  'make': 'SIG',
  'model': 'SG540',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 120945,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'G36K',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 100},
 {'frn': 121152,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'G36C',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 100},
 {'frn': 121424,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL8-5',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 121466,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 121540,
  'manufacturer': 'Hispano Argentina Fabrica de Automoviles SA (HAFDASA)',
  'make': 'HAFDASA',
  'model': 'Ballester Rigaud',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 54},
 {'frn': 121676,
  'manufacturer': 'Schweizerische Industrie-Gesellschaft (SIG)',
  'make': 'SIG',
  'model': 'SK46',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 121712,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'UMP SF',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 121940,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SLB2000 Light',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 122286,
  'manufacturer': 'Robinson Armament (RA)',
  'make': 'Robinson Armament',
  'model': 'M96 Recon',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 122814,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'TP9',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 122975,
  'manufacturer': 'Global Arms',
  'make': 'Global Arms',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 15},
 {'frn': 123337,
  'manufacturer': 'Beemiller',
  'make': 'Hi-Point Firearms',
  'model': '4095',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 123590,
  'manufacturer': 'Al Gi Mec',
  'make': 'Al Gi Mec',
  'model': 'AGM1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 124010,
  'manufacturer': 'Auto Ordnance Corporation (AOC)',
  'make': 'Auto Ordnance',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 15},
 {'frn': 124211,
  'manufacturer': 'Beemiller',
  'make': 'Hi-Point Firearms',
  'model': '4595',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 9},
 {'frn': 124548,
  'manufacturer': 'Action Legends Manufacturing (ALM)',
  'make': 'Action Legends Manufacturing',
  'model': 'M888 M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 125118,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'FN',
  'model': 'PS90',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 50},
 {'frn': 125235,
  'manufacturer': 'Beretta/Benelli',
  'make': 'Beretta',
  'model': 'Rx4 Storm',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 125479,
  'manufacturer': 'Haas & Storck (H&S)',
  'make': 'H&S',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 125499,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'MP7A1',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 40},
 {'frn': 125559,
  'manufacturer': 'LUSA USA',
  'make': 'LUSA',
  'model': 'LUSA A2',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 28},
 {'frn': 125593,
  'manufacturer': 'Volquartsen Custom',
  'make': 'Volquartsen',
  'model': 'Evolution',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 125704,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'FN',
  'model': 'FS2000',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 125740,
  'manufacturer': '',
  'make': 'Unknown',
  'model': 'SA M-1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 125781,
  'manufacturer': 'Farquhar & Hill',
  'make': 'Farquhar & Hill',
  'model': 'Farquhar & Hill Rifle',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 125981,
  'manufacturer': 'Steele, G M',
  'make': 'Steele, G M',
  'model': 'GM16',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 40},
 {'frn': 126100,
  'manufacturer': 'Chinese State Arsenal, JianShe',
  'make': 'Norinco',
  'model': 'Type 97A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 75},
 {'frn': 126160,
  'manufacturer': 'DMax',
  'make': 'DMax',
  'model': 'Auto Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 126180,
  'manufacturer': 'Robinson Armament (RA)',
  'make': 'Robinson Armament',
  'model': 'M96 Top Feed',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 126307,
  'manufacturer': 'Rheinische Metallwaaren & Maschinenfabrik',
  'make': 'Dreyse',
  'model': '1907',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 6},
 {'frn': 126519,
  'manufacturer': 'Sport Systeme Dittrich',
  'make': 'Sport Systeme Dittrich',
  'model': 'BD44',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 126788,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'RFB',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 126799,
  'manufacturer': 'Israel Weapon Industries (IWI)',
  'make': 'Tavor',
  'model': '21',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 127181,
  'manufacturer': 'Sport Systeme Dittrich',
  'make': 'Sport Systeme Dittrich',
  'model': 'BD42 (H)',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 127468,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '81 Woodsmaster Police',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 15},
 {'frn': 127610,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SLB2000+',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 127621,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'TP9US',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 127727,
  'manufacturer': 'Transformational Defense Industries (TDI)',
  'make': 'Kriss',
  'model': 'Kriss Vector CRB/SO',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 127752,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL8-6',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 127793,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': 'Type 56 SKS NR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 127991,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'TP9 Canada',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128201,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'G36KV',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 128258,
  'manufacturer': 'Fabrique Nationale Manufacturing Inc (FNMI)',
  'make': 'FN',
  'model': 'FNAR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 128455,
  'manufacturer': 'Sport Systeme Dittrich',
  'make': 'Sport Systeme Dittrich',
  'model': 'BD42',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 128590,
  'manufacturer': 'MOLOT',
  'make': 'Simonov',
  'model': 'OP-SKS',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 128603,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1006',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128614,
  'manufacturer': 'Lewis Machine & Tool (LMT)',
  'make': 'Springfield Armory',
  'model': 'US Carbine M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128615,
  'manufacturer': 'Inland Manufacturing Division Of General Motors',
  'make': 'US Carbine',
  'model': 'T3',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128616,
  'manufacturer': 'Winchester',
  'make': 'US Carbine',
  'model': 'T3',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128617,
  'manufacturer': 'Steelville Manufacturing',
  'make': 'Hy Hunter',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128620,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': 'Enforcer B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128621,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': 'Enforcer BN',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128622,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': 'Enforcer BG',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128647,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '30M-1BB',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128661,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': 'M1 Carbine 40th Anniversary Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128662,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '5006',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128663,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '5016',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128664,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1021',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128665,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1022',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128666,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1023',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128672,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'PM30G',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128673,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'PM30GT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128674,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'PM30S',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128676,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'PM30ST',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128677,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'P30D',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128680,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'P30DT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128681,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'PM30P',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128684,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'PPM30',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128685,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'P30SG',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 15},
 {'frn': 128706,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC30S',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128707,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC30SS',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128713,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC5.7S',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128715,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC5.7SS',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128716,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC30F',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128717,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC30FS',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128718,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC5.7F',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128719,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'SC5.7SF',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128720,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'PM30GH',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128721,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'PM30GW',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 128922,
  'manufacturer': 'Lacoste Engineering',
  'make': 'LDP',
  'model': 'LDP',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 128930,
  'manufacturer': 'Lacoste Engineering',
  'make': 'Kommando',
  'model': 'Kommando',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 25},
 {'frn': 129022,
  'manufacturer': 'SRM Arms',
  'make': 'SRM Arms',
  'model': '1216',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 16},
 {'frn': 129372,
  'manufacturer': 'National Ordnance',
  'make': 'Santa Fe',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 129390,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SL8-10',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 129752,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': 'Type 97A Sniper Rifle',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 129777,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 129882,
  'manufacturer': 'Plainfield Machine Company (PMC)',
  'make': 'Plainfield Machine',
  'model': 'M3 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 130180,
  'manufacturer': 'Iver Johnson',
  'make': 'Iver Johnson',
  'model': 'M1 Carbine 30G',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 130530,
  'manufacturer': 'Globe Firearms',
  'make': 'Globe Firearms',
  'model': '555',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 130531,
  'manufacturer': 'Globe Firearms',
  'make': 'Globe Firearms',
  'model': 'Mohawk',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 131827,
  'manufacturer': 'Technetwork (TNW)',
  'make': 'TNW',
  'model': 'Suomi M31',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 72},
 {'frn': 131999,
  'manufacturer': 'Just Right Carbines (JRC)/XLI Corporation',
  'make': 'Just Right Carbines',
  'model': 'JR Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 17},
 {'frn': 133356,
  'manufacturer': 'Haenel GmbH',
  'make': 'Haenel',
  'model': 'SLB2000+',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 133580,
  'manufacturer': 'Bushmaster Firearms',
  'make': 'Bushmaster',
  'model': 'BACR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 133585,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Winchester',
  'model': 'SX-AR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 134901,
  'manufacturer': 'Thureon Defense',
  'make': 'Thureon Defense',
  'model': 'SA',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 32},
 {'frn': 135840,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16F',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 135876,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': 'Type 63 SKS D',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 135878,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 135879,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 135883,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16C',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 135884,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16CA',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 135885,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16D',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 136198,
  'manufacturer': 'Taurus',
  'make': 'Taurus',
  'model': 'CT9 G2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 34},
 {'frn': 136204,
  'manufacturer': 'Taurus',
  'make': 'Taurus',
  'model': 'CT40 G2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 15},
 {'frn': 136205,
  'manufacturer': 'Taurus',
  'make': 'Taurus',
  'model': 'CT45 G2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 136296,
  'manufacturer': 'Union Switch & Signal/Quality Hardware & Machine',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 139282,
  'manufacturer': 'SRM Arms',
  'make': 'SRM Arms',
  'model': '1208',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 8},
 {'frn': 139283,
  'manufacturer': 'SRM Arms',
  'make': 'SRM Arms',
  'model': '1212',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 12},
 {'frn': 139318,
  'manufacturer': 'SRM Arms',
  'make': 'SRM Arms',
  'model': '12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 16},
 {'frn': 139415,
  'manufacturer': 'Zbrojovka Holice',
  'make': 'PPK',
  'model': 'Brno KMS 619',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 15},
 {'frn': 141202,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'Argo E',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 141280,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'Anakon SD12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 6},
 {'frn': 141281,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'Anakon TD12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 6},
 {'frn': 141381,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'MK10',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 9},
 {'frn': 142228,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Short Trac Hog Stalker',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 142760,
  'manufacturer': 'Chinese State Arsenal, JianShe',
  'make': 'EMEI',
  'model': 'T97NSR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 142967,
  'manufacturer': 'Kriss',
  'make': 'Kriss',
  'model': 'Kriss Vector SBR',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 142968,
  'manufacturer': 'Kriss',
  'make': 'Kriss',
  'model': 'Kriss Vector CRB',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 142979,
  'manufacturer': 'Caracal',
  'make': 'Caracal',
  'model': 'CC10',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 142985,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SG540-1 ERE Elite',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 143000,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SG542-1 ERE Elite',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 143001,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SG543-1 ERE Tactical Elite',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 143210,
  'manufacturer': 'Transformational Defense Industries (TDI)',
  'make': 'Kriss',
  'model': 'Kriss Vector SBR/SO',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 143346,
  'manufacturer': 'Israel Weapon Industries (IWI)/IWI US',
  'make': 'Tavor',
  'model': 'SAR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 32},
 {'frn': 143845,
  'manufacturer': 'Mountain Machine Works (MMW)/Armalite Incorporated',
  'make': 'Mountain Machine Works',
  'model': 'AR-180B',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 144222,
  'manufacturer': 'Floro International Corporation (FIC)',
  'make': 'Floro International',
  'model': 'US Rifle M1 Garand Conversion',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 145358,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'SU-16E',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 145433,
  'manufacturer': 'Technetwork (TNW)',
  'make': 'TNW',
  'model': '1999 ASR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 17},
 {'frn': 145651,
  'manufacturer': 'Czech Small Arms (CSA)',
  'make': 'Czech Small Arms',
  'model': 'SA VZ61 Combat',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 25},
 {'frn': 145908,
  'manufacturer': 'Kimar',
  'make': 'Chiappa Firearms',
  'model': 'M1-9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 17},
 {'frn': 146315,
  'manufacturer': 'Chiappa',
  'make': 'Citadel',
  'model': 'M1-9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 147547,
  'manufacturer': 'Alexander Arms (AA)',
  'make': 'Alexander Arms',
  'model': 'Ulfberht',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 147726,
  'manufacturer': 'Ohio Ordnance Works (OOW)',
  'make': 'Ohio Ordnance Works',
  'model': 'HCAR',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 147799,
  'manufacturer': 'Thureon Defense',
  'make': 'Thureon Defense',
  'model': 'GA',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 147899,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC300',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 147902,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC223',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 148680,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'Anakon MC-1980',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 6},
 {'frn': 148827,
  'manufacturer': 'MOLOT',
  'make': 'Tokarev',
  'model': 'KO-SVT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 148871,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS601 LX',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148873,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS601',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148877,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS602',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148880,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS603',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148881,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS701',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148908,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS701 LX',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148930,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS702',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148931,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS703',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148932,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS801',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 148933,
  'manufacturer': 'Aziz Arms',
  'make': 'Dragon',
  'model': 'AS901',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 149730,
  'manufacturer': 'James River Armory',
  'make': 'Rockola',
  'model': 'US Carbine M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 15},
 {'frn': 150726,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SG540-1 TI',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 150727,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SG543-1 TI',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 153447,
  'manufacturer': 'M+M Incorporated',
  'make': 'M+M',
  'model': 'M10X',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 155350,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'Argo E Pro',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 155567,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 3',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 157786,
  'manufacturer': 'German Sport Guns (GSG)',
  'make': 'German Sport Guns',
  'model': 'GSG MP40 Carbine',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 25},
 {'frn': 158310,
  'manufacturer': 'Allied Armament',
  'make': 'Allied Armament',
  'model': 'Shpagin PPSh41',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 71},
 {'frn': 158366,
  'manufacturer': '',
  'make': 'Asena',
  'model': 'Asena',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 11},
 {'frn': 158768,
  'manufacturer': 'Inland Manufacturing',
  'make': 'Inland Manufacturing',
  'model': 'M1A1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 158769,
  'manufacturer': 'Inland Manufacturing',
  'make': 'Inland Manufacturing',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 159406,
  'manufacturer': 'Torun Silah Sanayi',
  'make': 'Torun Arms',
  'model': 'TS H-1',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 9},
 {'frn': 160486,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Poly Technologies',
  'model': 'Type 81SA',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 163407,
  'manufacturer': 'Technetwork (TNW)',
  'make': 'TNW',
  'model': 'PPSH41 SA',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 71},
 {'frn': 163746,
  'manufacturer': 'Hatsan Arms',
  'make': 'Escort',
  'model': 'Escort Raider',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 164247,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'Anakon AK',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 164327,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'G5',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 164329,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'G3',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 11},
 {'frn': 164332,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'W12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 11},
 {'frn': 164333,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'W13',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 11},
 {'frn': 164334,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'SA19',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 11},
 {'frn': 164337,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'W11',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 11},
 {'frn': 164374,
  'manufacturer': 'M&U Silah',
  'make': 'Tomahawk',
  'model': 'SA1900',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 167262,
  'manufacturer': 'Pietta',
  'make': 'Pietta',
  'model': 'Kronos',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 167862,
  'manufacturer': 'Israel Weapon Industries (IWI)/IWI US',
  'make': 'Tavor',
  'model': 'X95',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 32},
 {'frn': 168362,
  'manufacturer': 'Balikli',
  'make': 'Balikli',
  'model': 'Catus',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 169002,
  'manufacturer': '',
  'make': 'Uzkon',
  'model': 'TR100',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 169042,
  'manufacturer': 'Jard',
  'make': 'Jard',
  'model': 'J67',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 170082,
  'manufacturer': '',
  'make': 'Uzkon',
  'model': 'UNG-12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 170242,
  'manufacturer': 'Franchi',
  'make': 'Franchi',
  'model': '1962 Carbine',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 171722,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC9-SA',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 172187,
  'manufacturer': 'NoDak Spud (NDS)',
  'make': 'NoDak Spud',
  'model': 'NDS-18S',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 173782,
  'manufacturer': 'Federal Ordnance',
  'make': 'Federal Ordnance',
  'model': '713 Mauser Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 175444,
  'manufacturer': "Strassell's Machine",
  'make': 'Hi-Point Firearms',
  'model': '3895',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 176546,
  'manufacturer': 'Inland Manufacturing',
  'make': 'Inland Manufacturing',
  'model': 'T30 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 178282,
  'manufacturer': 'Chinese State Arsenal, Factory 36',
  'make': 'Simonov',
  'model': 'Type 56 SKS',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 20},
 {'frn': 178642,
  'manufacturer': 'Ranger Silah',
  'make': 'Ranger',
  'model': 'Bullpup',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 179122,
  'manufacturer': 'Ranger Silah',
  'make': 'Ranger',
  'model': 'XT3',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 9},
 {'frn': 179162,
  'manufacturer': 'Ranger Silah',
  'make': 'Ranger',
  'model': 'XT3 Elite',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 9},
 {'frn': 180283,
  'manufacturer': 'Freedom Ordnance',
  'make': 'Freedom Ordnance',
  'model': 'FX-9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 33},
 {'frn': 181143,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC223-SA',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 182582,
  'manufacturer': 'Jard',
  'make': 'Jard',
  'model': 'J68',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 33},
 {'frn': 182762,
  'manufacturer': 'Kodiak Defence',
  'make': 'Kodiak Defence',
  'model': 'WK180C',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 184122,
  'manufacturer': 'Sturm Ruger',
  'make': 'Ruger',
  'model': 'PC Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 17},
 {'frn': 184202,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'GHM9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 185042,
  'manufacturer': "Strassell's Machine",
  'make': 'Hi-Point Firearms',
  'model': '4595',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 9},
 {'frn': 185103,
  'manufacturer': 'K&M Arms',
  'make': 'K&M Arms',
  'model': 'M17S-C308',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 185422,
  'manufacturer': 'K&M Arms',
  'make': 'K&M Arms',
  'model': 'M17S308',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 185582,
  'manufacturer': 'Adler Silah Sanayii',
  'make': 'Adler',
  'model': 'J410',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 186062,
  'manufacturer': 'Standard Manufacturing',
  'make': 'Standard Manufacturing',
  'model': 'SKO',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 186562,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'MK10 Gen 2',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 9},
 {'frn': 188265,
  'manufacturer': 'Doruk Silah',
  'make': 'Armed',
  'model': 'Stryker',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 9},
 {'frn': 188950,
  'manufacturer': 'Barak',
  'make': 'Barak',
  'model': 'BR36 CL-3',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 189302,
  'manufacturer': 'Best Arms',
  'make': 'Best Arms',
  'model': 'BA912',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 189928,
  'manufacturer': 'Czech Weapons',
  'make': 'Czech Weapons',
  'model': 'CSV-9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 190354,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'MK10 Magnum',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 190359,
  'manufacturer': 'Derya Arms',
  'make': 'Derya Arms',
  'model': 'MK12 Professional',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 7},
 {'frn': 190702,
  'manufacturer': "Strassell's Machine",
  'make': 'Hi-Point Firearms',
  'model': '1095',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 193102,
  'manufacturer': 'Chinese State Arsenal, Factory 606',
  'make': 'Simonov',
  'model': 'Type 56 SKS',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 194242,
  'manufacturer': 'Auto Ordnance Corporation (AOC)',
  'make': 'Auto Ordnance',
  'model': 'World War Two Remember Pearl Harbor M1 Carbine Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 15},
 {'frn': 194708,
  'manufacturer': 'Israel Weapon Industries (IWI)/IWI US',
  'make': 'Tavor',
  'model': '7',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 194723,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC308-SA',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 195152,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'GHM-G',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 195317,
  'manufacturer': 'Fulton Armory',
  'make': 'US Carbine',
  'model': 'M1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 10},
 {'frn': 195822,
  'manufacturer': 'Auto Ordnance Corporation (AOC)',
  'make': 'Auto Ordnance',
  'model': 'M1 Carbine D-Day 75th Anniversary Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 195824,
  'manufacturer': 'Auto Ordnance Corporation (AOC)',
  'make': 'Auto Ordnance',
  'model': 'M1A1 Carbine Airborne WW2 Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 195912,
  'manufacturer': 'Czech Weapons',
  'make': 'Czech Weapons',
  'model': 'TCR9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 195939,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC-G SA',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 195944,
  'manufacturer': 'Lone Wolf R & D',
  'make': 'Alpha Wolf',
  'model': 'PCC',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 17},
 {'frn': 196203,
  'manufacturer': "Strassell's Machine",
  'make': 'Hi-Point Firearms',
  'model': '995',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 196227,
  'manufacturer': 'Chinese State Arsenal, JianShe',
  'make': 'EMEI',
  'model': 'T97NSR-A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 196384,
  'manufacturer': 'Ceska Zbrojovka (CZ)',
  'make': 'CZ',
  'model': 'CZ Bren 2 Ms',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 30},
 {'frn': 196421,
  'manufacturer': 'Hamle AV Tufekleri',
  'make': 'Hunt Group',
  'model': 'MH-S',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 196562,
  'manufacturer': 'Lockhart Tactical',
  'make': 'Lockhart Tactical',
  'model': 'Raven',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 196840,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'GHM-320',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10},
 {'frn': 196996,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC9K',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 30},
 {'frn': 197126,
  'manufacturer': 'Brugger & Thomet (B&T)',
  'make': 'B&T',
  'model': 'APC9',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 33},
 {'frn': 197355,
  'manufacturer': 'Tokarev Savunma Silah Sanayi',
  'make': 'Tokarev USA',
  'model': 'TBP12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 20},
 {'frn': 197367,
  'manufacturer': 'Spectre Ltd',
  'make': 'Crusader Arms',
  'model': 'Crusader 9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 10}]

const frts_low = [{'frn': 455,
  'manufacturer': 'Harrington & Richardson',
  'make': 'Harrington & Richardson',
  'model': '360 Ultra Automatic',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 456,
  'manufacturer': 'Harrington & Richardson',
  'make': 'Harrington & Richardson',
  'model': '361 Ultra Automatic',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 2387,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': 'Type 56 SKS D',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 2493,
  'manufacturer': "Manufacture d'Armes de St-Etienne (MAS)",
  'make': 'MAS',
  'model': '1917 FA RSC',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 2904,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '740 Woodsmaster',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 2906,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '740 Woodsmaster ADL',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 2908,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '740 Woodsmaster BDL',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 2930,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '742 Woodsmaster',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 2941,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '742 Woodsmaster Canadian Centennial Gun Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 2978,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '7400',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 2981,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '7400 Special Purpose',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3016,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': 'Four',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3022,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': 'Four Diamond Anniversary Limited Edition',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3024,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': 'Four Peerless Grade',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3025,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': 'Four Premiere Grade',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3279,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '74 Auto Sportsman',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3672,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'FN Browning',
  'model': '1900',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 3695,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Standard',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3696,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Magnum',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 3702,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Lightweight',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3728,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Safari',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 3756,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'FN',
  'model': 'CAL',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 4225,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1910',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 4228,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '1910 Fancy Sporting',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 5035,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK604',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 4},
 {'frn': 5036,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK605',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 5037,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK606',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 5038,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK607',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 5039,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK608',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 5042,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK940',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 5045,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK630',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 18023,
  'manufacturer': 'Clement, Charles Ph',
  'make': 'Clement, Charles Ph',
  'model': 'Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 18026,
  'manufacturer': 'Neumann Freres',
  'make': 'Clement-Neumann',
  'model': 'Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 27004,
  'manufacturer': 'Voere',
  'make': 'Voere',
  'model': '2185',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 27006,
  'manufacturer': 'Voere',
  'make': 'Voere',
  'model': '2185 Match',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 27455,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '1003',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 27456,
  'manufacturer': 'Universal Firearms Corporation',
  'make': 'Universal',
  'model': '2560',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 31146,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': 'Four Premier Gold',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 35215,
  'manufacturer': 'Winchester',
  'make': 'Winchester',
  'model': '100',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 38303,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Safari Boss',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 38304,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Lightweight',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 44224,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': 'Type 56 SKS MC-5D',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 45946,
  'manufacturer': 'SAS Industries',
  'make': 'Brolin Arms',
  'model': 'SAS12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 47710,
  'manufacturer': 'Russian State Arsenals',
  'make': 'EAA',
  'model': 'MP-151',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 71429,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 84534,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Lightweight Boss',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 89435,
  'manufacturer': 'Sturm Ruger',
  'make': 'Ruger',
  'model': 'Deerfield Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 89475,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': 'ML12-1Z',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 96375,
  'manufacturer': 'Izhevsky Mashinostroitely Zavod (Izhmash)',
  'make': 'Baikal',
  'model': 'Medved',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 109596,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact Auto Battue',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 117509,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Lightweight Stalker',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 117510,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Lightweight Stalker Boss',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 118677,
  'manufacturer': 'Harrington & Richardson',
  'make': 'Harrington & Richardson',
  'model': 'Ultra Automatic',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 118796,
  'manufacturer': 'American Historical Foundation/Fabrique Nationale Herstal (FN)',
  'make': 'American Historical Foundation',
  'model': 'Browning BAR MARK 2 Deer Hunter Trophy Edition Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 119116,
  'manufacturer': "Manufacture d'Armes de St-Etienne (MAS)",
  'make': 'MAS',
  'model': '1944 Type A',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 120097,
  'manufacturer': 'Voere Voetter & Company',
  'make': 'Voere',
  'model': '2185 Sporter',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 120405,
  'manufacturer': 'Garys Gunsmithing',
  'make': 'Garys Gunsmithing',
  'model': 'Phoenix',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 4},
 {'frn': 120855,
  'manufacturer': 'Gamba, Renato',
  'make': 'Pioneer Arms',
  'model': 'Semi-Automatic',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 121140,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Short Trac',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 121209,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': "7400 Remington's 175th Anniversary Commemorative",
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 121395,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '7400 Weathermaster',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 121419,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'R1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 121503,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'HK603',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 4},
 {'frn': 122074,
  'manufacturer': "Erma's Firearms Manufacturing (EFM)",
  'make': 'Ermas Firearms Manufacturing',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 122432,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Long Trac',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 123545,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact Auto',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 123546,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact Auto Affut',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 123695,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact NT Battue',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 123697,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact NT Diamant',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 123699,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact NT 185th Anniversary Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 124860,
  'manufacturer': 'Heckler & Koch (HK)',
  'make': 'Heckler & Koch',
  'model': 'SLB2000 Target',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 125017,
  'manufacturer': '',
  'make': 'Winchester',
  'model': 'SXR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 125101,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '750 Woodsmaster',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 125366,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Short Trac Stalker',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 125367,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Long Trac Stalker',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 125403,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Safari 30-06 SPRG 100th Anniversary Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 125615,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': "742 Woodsmaster Remington's 150th Anniversary Commemorative",
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 126105,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': "7400 Remington's 180th Anniversary Commemorative",
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 126402,
  'manufacturer': 'Tulsky Oruzheiny Zavod (TOZ)',
  'make': 'TOZ',
  'model': 'MT-125',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 126885,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Classic',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 126888,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Elegance',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 127019,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'Argo EL',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 127095,
  'manufacturer': 'Merkel',
  'make': 'Merkel',
  'model': 'SR1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 127513,
  'manufacturer': 'Remington',
  'make': 'Remington',
  'model': '742 Woodsmaster United States Bicentennial Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 127729,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Winchester',
  'model': 'SXR Vulcan',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 127865,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Winchester',
  'model': 'SXR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 129485,
  'manufacturer': 'Brazilian State Arsenal, Itajuba',
  'make': 'Itajuba',
  'model': 'M954',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 129614,
  'manufacturer': 'SaskSten',
  'make': 'SaskSten',
  'model': 'Sten MARK 2',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 129714,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Zenith',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 129761,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'R1 Argo Limited Edition',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 130062,
  'manufacturer': 'Benelli',
  'make': 'Benelli',
  'model': 'MR1',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 130143,
  'manufacturer': 'SaskSten',
  'make': 'SaskSten',
  'model': 'Sten MARK 2 Rotary Magazine Model',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 130517,
  'manufacturer': 'SaskSten',
  'make': 'SaskSten',
  'model': 'Sten MARK 3',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 130588,
  'manufacturer': 'SaskSten',
  'make': 'SaskSten',
  'model': 'Sten New Zealand Pattern',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 130589,
  'manufacturer': 'SaskSten',
  'make': 'SaskSten',
  'model': 'Sten/Suomi FRT',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 130590,
  'manufacturer': 'SaskSten',
  'make': 'SaskSten',
  'model': 'Sten MARK 1*',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 130914,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact NT One',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 130916,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact NT Traqueur One',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 131898,
  'manufacturer': 'Izhevsky Mashinostroitely Zavod (Izhmash)',
  'make': 'Baikal',
  'model': 'Medved 4',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 137025,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'SAS',
  'model': 'SAS12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 138819,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR White Gold Medallion',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 140094,
  'manufacturer': 'Verney-Carron',
  'make': 'Verney-Carron',
  'model': 'Impact NT Etendard',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 142981,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Executive',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 143037,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 2 Safari Steel',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 143081,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Winchester',
  'model': 'SXR Vulcan Battue',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 143862,
  'manufacturer': 'MOLOT',
  'make': 'MOLOT',
  'model': 'Tucan',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 144942,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Norinco',
  'model': '1000',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 146047,
  'manufacturer': 'Fabrica y Maestranzas del Ejercito (FAMAE)',
  'make': 'FAMAE',
  'model': 'SG542-1 SA',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 146276,
  'manufacturer': 'Kuandian',
  'make': 'Lever Arms',
  'model': 'LA-K12 Puma',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 147862,
  'manufacturer': 'Jard',
  'make': 'Jard',
  'model': 'J48',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 148207,
  'manufacturer': 'America Remembers/Fabrique Nationale Herstal (FN)',
  'make': 'America Remembers',
  'model': 'Browning BAR MARK 2 Mule Deer Foundation Tribute',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 148245,
  'manufacturer': 'America Remembers/Fabrique Nationale Herstal (FN)',
  'make': 'America Remembers',
  'model': 'Browning BAR MARK 2 RMEF Tribute',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 149866,
  'manufacturer': 'Kel Tec CNC Industries',
  'make': 'Kel Tec',
  'model': 'RDB',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 149886,
  'manufacturer': 'K&M Arms',
  'make': 'K&M Arms',
  'model': 'M17S-C',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 150428,
  'manufacturer': 'American Historical Foundation/Auto Ordnance Corporation (AOC)',
  'make': 'American Historical Foundation',
  'model': 'Auto Ordnance M1 Carbine US Air Force Commemorative',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 154406,
  'manufacturer': 'Zastava Arms',
  'make': 'Zastava',
  'model': 'M84A',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 154587,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 GTI',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 154606,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Hybrid',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 2},
 {'frn': 154607,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Classic XT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 154608,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Forest XT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 154609,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Hardwood',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 154610,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Synchro XT',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 154612,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Black Velvet',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 154613,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Synchro XT Ultra',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 155566,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR Match',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 156006,
  'manufacturer': 'Izhevsky Mekhanichesky Zavod (Izhmech)',
  'make': 'Baikal',
  'model': 'Izubr',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 157266,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Thumbhole',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 157267,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 157268,
  'manufacturer': 'Sauer & Sohn (S&S)',
  'make': 'Sauer',
  'model': '303 Synthetic',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 162466,
  'manufacturer': 'BS Ordnance',
  'make': 'BS Ordnance',
  'model': 'Sten MARK 3',
  'type': 'Commercial Version',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 168284,
  'manufacturer': 'Izhevsky Mashinostroitely Zavod (Izhmash)',
  'make': 'Baikal',
  'model': 'Medved 2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 3},
 {'frn': 168285,
  'manufacturer': 'Izhevsky Mashinostroitely Zavod (Izhmash)',
  'make': 'Baikal',
  'model': 'Medved 3',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 168782,
  'manufacturer': 'Fabrique Nationale Herstal (FN)',
  'make': 'Browning',
  'model': 'BAR MARK 3 50th Anniversary Limited Edition',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 184544,
  'manufacturer': 'Final Defense Industries',
  'make': 'Final Defense',
  'model': 'FD12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 187362,
  'manufacturer': 'Husan Arms',
  'make': 'Canuck',
  'model': 'Spectre',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 189043,
  'manufacturer': 'Doruk Silah',
  'make': 'Armed',
  'model': 'SF12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 191402,
  'manufacturer': 'Pietta',
  'make': 'Pietta',
  'model': 'Chronos',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 192122,
  'manufacturer': 'Kirici',
  'make': 'Kirici',
  'model': 'NX 18',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 194716,
  'manufacturer': 'Kodiak Defence',
  'make': 'Kodiak Defence',
  'model': 'WK181C',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 194728,
  'manufacturer': 'Iron Armi',
  'make': 'Iron Armi',
  'model': 'Mito',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 194729,
  'manufacturer': 'Iron Armi',
  'make': 'Iron Armi',
  'model': 'Mito Slug',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 195083,
  'manufacturer': 'Panzer Silah Sanayi',
  'make': 'Panzer Arms',
  'model': 'BP-12',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 195457,
  'manufacturer': 'Contrini Officiene Meccaniche di Contrini Giovanni (COM)',
  'make': 'COM',
  'model': 'Westarm',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 195599,
  'manufacturer': 'Hima Arms Ltd.',
  'make': 'Tracker Arms',
  'model': 'HG-105',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 195699,
  'manufacturer': 'Jard',
  'make': 'Jard',
  'model': 'J180',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 195878,
  'manufacturer': 'Russian State Arsenals/Kodiak Defence',
  'make': 'Kodiak Defence',
  'model': 'Scorpio SKS-15',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196013,
  'manufacturer': '',
  'make': 'Dlask Arms',
  'model': 'SKS M',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196206,
  'manufacturer': 'NoDak Spud (NDS)',
  'make': 'NoDak Spud',
  'model': 'NDS-18SC',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196226,
  'manufacturer': 'Landor Arms',
  'make': 'Landor Arms',
  'model': 'BPX 905',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196276,
  'manufacturer': 'Spectre Ltd',
  'make': 'Spectre Ltd',
  'model': 'WS-MCR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196585,
  'manufacturer': 'Black Creek Labs',
  'make': 'Black Creek Labs',
  'model': 'SRV2 Siberian',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196613,
  'manufacturer': 'Black Creek Labs',
  'make': 'Black Creek Labs',
  'model': 'SRV2 Badger',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196625,
  'manufacturer': 'KRC AV SANAYI',
  'make': 'Revolution Armory',
  'model': 'NX 18',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 196688,
  'manufacturer': 'Kodiak Defence',
  'make': 'Kodiak Defence',
  'model': 'K9',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 197056,
  'manufacturer': 'Sterling Arms International Inc.',
  'make': 'Sterling Arms International',
  'model': 'R18 MK2',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 197110,
  'manufacturer': 'Arttech',
  'make': 'Canuck',
  'model': 'Arttech',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 4},
 {'frn': 197160,
  'manufacturer': 'Hawk',
  'make': 'Blue Line',
  'model': 'BL-18 12S',
  'type': 'Shotgun',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5},
 {'frn': 197258,
  'manufacturer': '',
  'make': 'Unknown',
  'model': 'M1 Carbine',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Restricted',
  'capacity_max': 5},
 {'frn': 197368,
  'manufacturer': 'Chinese State Arsenals',
  'make': 'Poly Technologies',
  'model': 'Type 81SR',
  'type': 'Rifle',
  'action': 'Semi-Automatic',
  'legal_classification': 'Non-Restricted',
  'capacity_max': 5}]

// Set apiConnector in search-ui config
config.apiConnector = connector
config.onSearch = (state, queryConfig, next) => {
      const updatedState = removeValidDate(state);
      return next(updatedState, queryConfig);
    }

// Define styles
const useStyles = makeStyles(theme => ({
  tableContainer: {
    marginTop: 20,
  },
  h3Header: {
    marginTop: 50,
  },
  table: {
    minWidth: 650,
  },
  frtTableContainer: {
    marginTop: 24,
  },
  mobileMessage: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  }
}));

const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
}

// HomePage componenet
export default function FirearmsMore() {
  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={({
        wasSearched,
        isLoading }) => ({
          wasSearched,
          isLoading })}>
        {({
          wasSearched,
          isLoading }) => {

          // Flag for loading and no prior search
          var initial_loader = (isLoading && !wasSearched) ? true : false;

          // Reducer for SiteInfo component
          const [stateSI, dispatchSI] = useReducer(reducer, initialState.siteInfo);

          // Set styles
          const classes = useStyles();

          return (
            <div className="App">
            <ErrorBoundary>
              <Header dispatchSI={ dispatchSI } disableSearch={ true }/>
              <Layout
                header={
                  <>
                    <Authenticator hideDefault={true}>
                      <LayoutHeader dispatchSI={ dispatchSI } pageName="Firearms Potentially Affected by Amendment G4"/>
                    </Authenticator>
                    <div className={classes.frtTableContainer}>
                      <h2>Firearms Potentially Affected by Amendment G4</h2>
                      <Typography variant="body1" component="h3" gutterBottom>The following tables contain lists of currently Non-Restricted and Restricted firearm models that, based on descriptions in the FRT, appear to meet the definition in amendment G4 of Bill C-21.</Typography>
                      <Typography variant="body1" component="h3" gutterBottom><HashLink scroll={(el) => scrollWithOffset(el)} to={'/firearms-potentially-affected-by-amendment-g4#table1'}>Table 1</HashLink> contains firearms having an FRT configuration with shot capacity greater than 5 cartridges.</Typography>
                      <Typography variant="body1" component="h3" gutterBottom><HashLink scroll={(el) => scrollWithOffset(el)} to={'/firearms-potentially-affected-by-amendment-g4#table2'}>Table 2</HashLink> contains firearms having FRT configurations with shot capacity less than or equal to 5 cartridges.  Magazines with capacity greater than 5 may still exist for these firearms.</Typography>
                      <Typography variant="body1" component="h3" gutterBottom><a target="_blank" rel="noopener noreferrer" href="https://armalytics-public.s3.amazonaws.com/frt-lists/Firearms+Potentially+Affected+by+Amendment+G4+V1.1.xlsx">Download Tables</a></Typography>
                      <Typography variant="subtitle2" component="h4">Disclaimer: The accuracy of this list is not guaranteed.  Firearms may be missing from this list and some firearms listed may not meet the definition.</Typography>
                      <Typography variant="subtitle2" component="h4">Magazines may exist for firearms in Table 2 with capacity over 5 shots.</Typography>
                      <Typography variant="subtitle2" component="h4">This list does not contain firearms that may be prescribed by name.</Typography>

                      <section  id="table1">
                        <h3 className={classes.h3Header}>Table 1: Over 5 Shot Capacity</h3>
                        <TableContainer className={classes.tableContainer} component={Paper}>
                          <Table className={classes.table} stickyHeader  aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>FRN</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Manufacturer</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Make</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Model</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Type</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Action</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Legal Classification</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Max Capacity</Typography></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(frts.length > 0) ? frts.map((row, i) => (
                                <TableRow key={i}>
                                  <TableCell component="th" scope="row"><Typography variant="body2" className={classes.tableRow}>{row.frn}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.manufacturer}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.make}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.model}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.type}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.action}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.legal_classification}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.capacity_max}</Typography></TableCell>
                                </TableRow>
                              )) : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </section>
                      <section  id="table2">
                        <h3 className={classes.h3Header}>Table 2: 5 and Under Shot Capacity</h3>
                        <TableContainer className={classes.tableContainer} component={Paper}>
                          <Table className={classes.table} stickyHeader  aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>FRN</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Manufacturer</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Make</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Model</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Type</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Action</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Legal Classification</Typography></TableCell>
                                <TableCell align="left"><Typography variant="subtitle2" className={classes.tableHeader}>Max Capacity</Typography></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(frts_low.length > 0) ? frts_low.map((row, i) => (
                                <TableRow key={i}>
                                  <TableCell component="th" scope="row"><Typography variant="body2" className={classes.tableRow}>{row.frn}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.manufacturer}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.make}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.model}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.type}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.action}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.legal_classification}</Typography></TableCell>
                                  <TableCell align="left"><Typography variant="body2" className={classes.tableRow}>{row.capacity_max}</Typography></TableCell>
                                </TableRow>
                              )) : null}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </section>
                    </div>

                  </>
                }
                sideContent={
                  null
                }
                bodyContent={
                  null
                }
                bodyHeader={
                  null
                }
                bodyFooter={null}
              />
            </ErrorBoundary>
              <Footer dispatchSI={ dispatchSI } />
              <SiteInfo stateSI={ stateSI } dispatchSI={ dispatchSI } />
              { (!initial_loader && wasSearched) && <ScrollToTopButton /> }
              <Helmet>
                <title>Canadian Firearms Data & Analytics | Armalytics</title>
              </Helmet>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}
