import React from "react";
import PropTypes from "prop-types";
import { withSearch } from "@elastic/react-search-ui";

function ClearFilters({ filters, clearFilters }) {
  const styles = {
           button: {
             marginBottom: "32px"
          }
        }
  return (
    <div style={styles.button}>
      <a href="#" className="sui-facet btn btn-info" role="button" onClick={() => clearFilters()}>
        Reset Filters ({ filters.length })
      </a>
    </div>
  );
}

export default withSearch(({ filters, clearFilters }) => ({
  filters,
  clearFilters
}))(ClearFilters);

ClearFilters.propTypes = {
    filters: PropTypes.any,
    clearFilters: PropTypes.any,
};
